import React, { useEffect, useState } from 'react';
import { useIsMobile } from '../../../../hooks/IsMobileHook';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import { IStore } from '../../../../types';
import { loginThunk } from '../../redux/authSlice';
import { handleNewErrorThunk } from '../../../App/redux/errorSlice';
import { continueViaSocialThunk } from '../../redux/authSocialSlice';
import BookitAlertBox from '../../../../components/foundation/BookitAlertBox/BookitAlertBox';
import { text } from '../../../../constants/constants';
import styles from './LoginPage.style';
import GoogleAuth from '../../components/GoogleAuth/GoogleAuth';
import BookitInputField from '../../../../components/foundation/BookitInputField/BookitInputField';
import BookitButton from '../../../../components/foundation/BookitButton/BookitButton';
import SunriseLogo from '../../../../customerComponents/components/SunriseLogo/SunriseLogo';
import BookitSnackbar from '../../../../components/foundation/BookitSnackbar/BookitSnackbar';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [usernameValid, setUsernameValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const isMobile = useIsMobile();
  const buttonDisabled = !usernameValid || !passwordValid;

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch<AppDispatch>();
  const { loading } = useSelector((store: IStore) => store.auth);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleLoginClick = async () => {
    try {
      await dispatch(loginThunk({ email, password })).unwrap();
      navigate('/reservations');
    } catch (error) {
      dispatch(handleNewErrorThunk(error));
    }
  };

  const handleLoginSocial = async (socialToken) => {
    try {
      const response = await dispatch(continueViaSocialThunk({ token: socialToken, client: 'GOOGLE' })).unwrap();
      if (!response) {
        navigate('/signup', { state: { googleToken: socialToken } });
      } else {
        navigate('/reservations');
      }
    } catch (error) {
      dispatch(handleNewErrorThunk(error));
    }
  };

  const handleEnterKeyDown = (event) => {
    if (event.key === 'Enter' && !buttonDisabled) {
      handleLoginClick();
    }
  };

  const renderAlertBox = () => <BookitAlertBox type={'warning'}>{text.LOGIN.LOGIN_FIRST}</BookitAlertBox>;
  const renderDivider = () => (
    <div style={styles.dividerTextWrapper}>
      <hr style={styles.divider} />
      <span style={styles.dividerText}>{text.LOGIN.DIVIDER_TEXT}</span>
      <hr style={styles.divider} />
    </div>
  );

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const renderGoogleAuth = () => (
    <GoogleAuth
      onSuccess={(token) => {
        handleLoginSocial(token);
      }}
      onError={() => setSnackbarOpen(true)}
    />
  );

  const renderInputFields = () => (
    <>
      <BookitInputField
        variant='outlined'
        label={text.LOGIN.EMAIL}
        onChange={(e, valid) => {
          setEmail(e.target.value);
          setUsernameValid(valid);
        }}
        value={email}
        type='email'
        helperText={text.EMAIL_HELPER_TEXT}
        required
        size='medium'
        fieldTheme='light'
      />
      <BookitInputField
        variant='outlined'
        label={text.LOGIN.PASSWORD}
        onChange={(e, valid) => {
          setPassword(e.target.value);
          setPasswordValid(valid);
        }}
        value={password}
        type='password'
        required
        size='medium'
        fieldTheme='light'
      />
    </>
  );

  const renderForgotPass = () => (
    <div style={styles.forgotPasswordWrapper}>
      <BookitButton
        variant='text'
        type='basic'
        label={text.LOGIN.FORGOT_PASS}
        color='primary'
        onClick={() => navigate('/forgot-password')}
        style={styles.forgotPasswordButton}
      />
    </div>
  );

  const renderNoAccountButton = () => (
    <>
      <div style={styles.signupButtonWrapper}>
        <span style={styles.noAccountText}>{text.LOGIN.NO_ACCOUNT}</span>
        <BookitButton
          variant='text'
          type='basic'
          label={text.SIGNUP.NO_ACCOUNT_BUTTON}
          color='primary'
          onClick={() => navigate('/signup')}
          style={styles.signupButton}
        />
      </div>
    </>
  );

  const renderLeftSide = () => (
    <div style={isMobile ? styles.mobileImageWrapper : styles.leftSideWrapper}>
      <div style={isMobile ? styles.mobileLogoWrapper : styles.logoWrapper}>
        <SunriseLogo size='medium' isLogoWhite={true} />
      </div>
      {!isMobile && (
        <div style={styles.contentWrapper}>
          <p style={styles.title}>{text.LOGIN.TITLE}</p>
          <p style={styles.text}>{text.LOGIN.WELCOME_TEXT}</p>
        </div>
      )}
    </div>
  );

  const renderRightSide = () => (
    <div style={styles.rightSideWrapper}>
      {isMobile && (
        <div style={styles.mobileContentWrapper}>
          <p style={styles.titleMobile}>{text.LOGIN.TITLE}</p>
          <p style={styles.text}>{text.LOGIN.WELCOME_TEXT}</p>
        </div>
      )}
      {location.state?.fromSubmitReservation && renderAlertBox()}
      <div style={styles.formWrapper}>
        {renderGoogleAuth()}
        <BookitSnackbar open={snackbarOpen} onClose={handleSnackbarClose} type='error' text={text.GOOGLE_AUTH_ERROR_MESSAGE} />
        {renderDivider()}
        {renderInputFields()}
        {renderForgotPass()}
      </div>
      <div style={styles.loginButtonWrapper}>
        <BookitButton
          variant='contained'
          type='basic'
          label={text.LOGIN.LOGIN}
          color='primary'
          onClick={handleLoginClick}
          disabled={buttonDisabled}
          style={styles.loginButton}
          loading={loading.login}
        />
      </div>
      {renderNoAccountButton()}
    </div>
  );

  return (
    <div style={isMobile ? styles.mobileWrapper : styles.wrapper} onKeyDown={handleEnterKeyDown}>
      {renderLeftSide()}
      {renderRightSide()}
    </div>
  );
};

export default LoginPage;
