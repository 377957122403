import React from 'react';
import styles from './BookitFooterAdvanced.style';
import { useIsMobile } from '../../../hooks/IsMobileHook';

interface IProps {
  children: React.ReactNode;
}

const BookitFooterAdvanced = ({ children }: IProps) => {
  const isMobile = useIsMobile();

  return (
    <div style={isMobile ? styles.mainContainerMobile : styles.mainContainer}>
      {children}
    </div>
  );
};

export default BookitFooterAdvanced;
