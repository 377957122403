import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useStyles } from '../BookitTable/BookitTable.style';
import { BookitTableSearch } from './BookitTableSearch';
import { text } from '../../../constants/constants';

interface IProps {
  tableData: any;
  searchAvailable?: string[];
}

const BookitTable = ({ tableData, searchAvailable }: IProps) => {
  const [search, setSearch] = React.useState('');
  const [searchBy, setSearchBy] = React.useState(null);
  const [filteredTableData, setFilteredTableData] = React.useState(tableData);

  const styles = useStyles();
  const firstRow = tableData[0];

  useEffect(() => {
    if (search.length === 0 || !searchBy) {
      setFilteredTableData(tableData);
      return;
    }

    const filteredData = tableData.filter((row) => {
      return row[searchBy]?.content.toLowerCase().includes(search.toLowerCase());
    });
    setFilteredTableData(filteredData);
  }, [search, tableData]);

  useEffect(() => {
    setSearch('');
  }, [searchBy]);

  const renderHeader = () => {
    return (
      <thead>
        <tr className={styles.tableHeader}>
          {Object.keys(firstRow).map((key, index) => {
            // console.log('key je: ', key);
            return (
              !firstRow[key].hidden && (
                <th key={`${index}-renderTableHeader`} className={clsx(styles.defaultTableCell, firstRow[key].style)}>
                  {searchAvailable && searchAvailable.includes(key) ? (
                    <BookitTableSearch
                      title={firstRow[key].columnName}
                      onChangeValue={(value) => setSearch(value)}
                      onIconPress={() => setSearchBy(key)}
                      searchInputVisibleForKey={searchBy}
                      columnKey={key}
                      value={search}
                    />
                  ) : (
                    firstRow[key].columnName
                  )}
                </th>
              )
            );
          })}
        </tr>
      </thead>
    );
  };

  const renderBody = () => {
    if (!filteredTableData.length) {
      return <p className={styles.noData}>{text.BO_NO_SEARCH_DATA}</p>;
    }
    return (
      <tbody>
        {filteredTableData.map((row, index) => {
          return (
            <tr key={`${index}-renderTableRow`} className={styles.tableRow}>
              {Object.keys(row).map((rowKey) => {
                const rowValue = row[rowKey];
                const { style, content, hidden } = rowValue;
                if (hidden) return null;
                return <td className={clsx(styles.defaultTableCell, style)}>{content}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };

  const renderTable = () => (
    <table style={{ width: '100%' }}>
      {renderHeader()}
      {renderBody()}
    </table>
  );
  return renderTable();
};

export default BookitTable;
