import React, { useCallback, useEffect, useState } from 'react';
import BookitDialog from '../../../../../components/foundation/BookitDialog/BookitDialog';
import { TextField } from '@mui/material';
import { text } from '../../../../../constants/constants';
import styles from './BackOfficeViewEventDialog.style';
import { BackOfficeBlockUserAction } from '../../components/BackOfficeBlockUserAction/BackOfficeBlockUserAction';
import BookitButton from '../../../../../components/foundation/BookitButton/BookitButton';
import CancelReservationDialog from '../../../../../dialogs/CancelReservationDialog/CancelReservationDialog';
import { formatDateToHoursString, formatDateToRegular } from '../../../../../utils/utils';
import {
  cancelBoAppointmentThunk,
  getBoScheduledAppointmentsThunk,
  updateBoAppointmentThunk,
} from '../../redux/boAppointmentsSlice';
import { handleNewErrorThunk } from '../../../../App/redux/errorSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../store/store';
import BookitDropdown from '../../../../../components/foundation/BookitDropdownField/BookitDropdownField';

interface IProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (reservation: any) => void;
  selectedDate: Date;
  selectedSlotData?: any;
  loading: boolean;
}

const localeDateString = 'sr-RS';

const BackOfficeViewEventDialog = ({ open, onClose, onConfirm, selectedSlotData, selectedDate, loading }: IProps) => {
  const [note, setNote] = useState(!selectedSlotData.userName ? selectedSlotData.title : selectedSlotData.note);
  const [date, setDate] = useState(new Date().toDateString());
  const [time, setTime] = useState(new Date().toTimeString());
  const [service, setService] = useState(null);

  const [isCancelReservationDialogOpen, setIsCancelReservationDialogOpen] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const refreshTableData = useCallback(async () => {
    await dispatch(getBoScheduledAppointmentsThunk({ date: selectedDate.toDateString() })).unwrap();
    onClose();
  }, []);

  const handleCancelAppointment = async (appointmentId) => {
    try {
      await dispatch(cancelBoAppointmentThunk(appointmentId)).unwrap();
      setIsCancelReservationDialogOpen(false);
      await refreshTableData();
    } catch (e) {
      dispatch(handleNewErrorThunk(e));
    }
  };

  const handleAppointmentUpdate = async () => {
    const payload = {
      appointmentId: selectedSlotData.appointmentId,
      appointmentDetails: {
        service_id: service.id,
        resource_id: selectedSlotData.resourceId,
        start_date: selectedSlotData.start,
        end_date: selectedSlotData.end,
        note: note,
        delete_note: true,
      },
    };

    try {
      await dispatch(updateBoAppointmentThunk(payload));
      refreshTableData();
    } catch (e) {
      dispatch(handleNewErrorThunk(e));
    }
  };

  useEffect(() => {
    setDate(new Date(selectedSlotData?.start).toLocaleDateString(localeDateString));
    setTime(new Date(selectedSlotData?.start).toLocaleTimeString(localeDateString));

    const selectedService = selectedSlotData?.services?.find((s) => s.id === selectedSlotData.selectedService.id);
    selectedService && setService(selectedService);
  }, [selectedSlotData]);

  const renderNote = () => (
    <div style={styles.noteWrapper}>
      <p style={styles.noteTitle}>{text.BO_VIEW_EVENT_DIALOG.NOTE_TITLE}</p>
      <TextField
        multiline
        placeholder={text.BO_VIEW_EVENT_DIALOG.NOTE_PLACEHOLDER}
        value={note}
        onChange={(e) => setNote(e.target.value)}
        maxRows={4}
        sx={styles.note}
      />
    </div>
  );

  const renderCancelReservationButton = () => {
    return (
      <>
        <div style={styles.cancelReservationButtonWrapper}>
          <BookitButton
            variant={'text'}
            label={text.BO_VIEW_EVENT_DIALOG.CANCEL_RESERVATION_BUTTON}
            type={'basic'}
            onClick={() => {
              setIsCancelReservationDialogOpen(true);
            }}
          />
        </div>
        <CancelReservationDialog
          open={isCancelReservationDialogOpen}
          onClose={() => {
            setIsCancelReservationDialogOpen(false);
          }}
          onConfirm={() => {
            handleCancelAppointment(selectedSlotData?.appointmentId);
          }}
        >
          <p style={styles.cancelReservationDialogText}>
            {selectedSlotData &&
              `Termin ${formatDateToRegular(selectedSlotData?.start)} u ${formatDateToHoursString(
                selectedSlotData?.start,
              )} na terenu ${selectedSlotData?.resourceName} biće otkazan.`}
          </p>
        </CancelReservationDialog>
      </>
    );
  };

  const renderSportDropdown = () => {
    return (
      <div style={styles.filedWrapper}>
        <p>{text.BO_ADD_RESERVATION_DIALOG.SPORT_TITLE}</p>

        <BookitDropdown
          options={selectedSlotData.services}
          value={service}
          getOptionLabel={(option: { id: string; name: string }) => option.name}
          placeholder={(params) => <TextField {...params} label={text.BO_ADD_RESERVATION_DIALOG.DROPDOWN_PLACEHOLDER} />}
          onChange={(event, newValue) => {
            setService(newValue);
          }}
          disableClearable
          autoHighlight
          style={styles.sportDropdown}
        />
      </div>
    );
  };

  return (
    <BookitDialog
      buttonContainerStyle={styles.buttonContainer}
      buttonStyle={styles.button}
      open={open}
      onClose={onClose}
      onConfirm={handleAppointmentUpdate}
      titleLabel={text.BO_VIEW_EVENT_DIALOG.TITLE}
      titleStyle={styles.title}
      discardButtonColor='primary'
      confirmButtonColor='primary'
      discardLabel={text.DIALOG_TEXT.DEFAULT_DISCARD}
      confirmLabel={text.DIALOG_TEXT.DEFAULT_CONFIRM}
      confirmButtonDisabled={!note}
      loading={loading}
    >
      <div style={styles.dialogContainer}>
        {selectedSlotData.userName && (
          <>
            <div style={styles.filedWrapper}>
              <p>{text.BO_VIEW_EVENT_DIALOG.NAME_TITLE}</p>
              <div style={styles.nameAndBlockUserIconWrapper}>
                <p style={styles.name}>{selectedSlotData.userName}</p>
                <BackOfficeBlockUserAction
                  user={{ userFullName: selectedSlotData.userName, id: selectedSlotData.userId }}
                  onClose={() => {
                    setIsCancelReservationDialogOpen(false);
                  }}
                  blockUserCallback={refreshTableData}
                />
              </div>
            </div>
            <div style={styles.filedWrapper}>
              <p>{text.BO_VIEW_EVENT_DIALOG.PHONE_TITLE}</p>
              <p>{selectedSlotData.userPhoneNumber}</p>
            </div>
          </>
        )}
        <div style={styles.filedWrapper}>
          <p>{text.BO_VIEW_EVENT_DIALOG.DATE_TITLE}</p>
          <p>{date}</p>
        </div>
        <div style={styles.filedWrapper}>
          <p>{text.BO_VIEW_EVENT_DIALOG.TIME_TITLE}</p>
          <p>{time}</p>
        </div>
        <div style={styles.filedWrapper}>
          <p>{text.BO_VIEW_EVENT_DIALOG.FIELD_TITLE}</p>
          <p>{selectedSlotData.resourceName}</p>
        </div>
        {renderSportDropdown()}
        {renderNote()}
        {renderCancelReservationButton()}
      </div>
    </BookitDialog>
  );
};

export default BackOfficeViewEventDialog;
