import { makeStyles } from '@mui/styles';
import theme from '../../../../../themes/theme';

export const useStyles = makeStyles({

  refreshTextAndIconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  refreshText: {
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    marginRight: '1rem'
  },
});
