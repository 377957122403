import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import { motion } from 'framer-motion';
import BookitButton from '../../../../../components/foundation/BookitButton/BookitButton';
import BookitIcon from '../../../../../components/foundation/BookitIcon/BookitIcon';
import { useStyles } from './BackOfficeRefresh.style';
import { text } from '../../../../../constants/constants';

interface BackOfficeRefreshProps {
  onRefresh: () => Promise<void>;
  refreshTitle?: string;
}

const BackOfficeRefresh: React.FC<BackOfficeRefreshProps> = ({ onRefresh, refreshTitle = text.BO_REFRESH.TITLE }) => {
  const styles = useStyles();
  const [rotation, setRotation] = useState(0);
  const [lastRefreshTime, setLastRefreshTime] = useState(new Date());

  const handleRefresh = async () => {
    setRotation((prevRotation) => prevRotation + 360);
    await onRefresh();
    setLastRefreshTime(new Date());
  };

  const getRefreshTime = () => {
    const date = lastRefreshTime.toLocaleDateString('sr-SR');
    const time = `${lastRefreshTime.getHours()}:${lastRefreshTime.getMinutes()}:${lastRefreshTime.getSeconds()}`;
    return `${refreshTitle} ${date} u ${time}`;
  };

  return (
    <div className={styles.refreshTextAndIconWrapper}>
      <p className={styles.refreshText}>{getRefreshTime()}</p>
      <BookitButton
        variant='outlined'
        onClick={handleRefresh}
        label={
          <Tooltip title={text.BO_REFRESH.TOOLTIP}>
            <motion.div
              animate={{ rotate: rotation, transition: { duration: 0.5 } }}
              style={{ marginTop: 5, transformOrigin: 'center' }}
            >
              <BookitIcon type='refresh' />
            </motion.div>
          </Tooltip>
        }
        type='basic'
      />
    </div>
  );
};

export default BackOfficeRefresh;
