import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../../../../services/api-service';

const initialState = {
  profile: {},
};

const boProfileSlice = createSlice({
  name: 'BACK_OFFICE_PROFILE',
  initialState,
  reducers: {
    importBoProfileAction(state, action) {
      state.profile = action.payload;
    },
  },
});

export const { importBoProfileAction } = boProfileSlice.actions;
export default boProfileSlice.reducer;

export const setBoProfileThunk = createAsyncThunk('SET_BACK_OFFICE_PROFILE', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const data = await ApiService.backOfficeHomeService().getBoProfile();
    dispatch(importBoProfileAction(data));
  } catch (error) {
    return rejectWithValue({
      code: error.code,
      message: error.message,
      status: error.status,
    });
  }
});
