import { makeStyles } from '@mui/styles';
import theme from '../../../../../themes/theme';

export const useStyles = makeStyles({

  mainWrapper: {
    width: '90%',
    paddingTop: '3rem',
    height: '100vh'
  },

  tableWrapper: {
    overflowY: 'auto',
    height: '85%',
  },

  noData: {
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.h2.fontSize,
    fontFamily: theme.typography.h2.fontFamily,
    margin: '3rem 0 0 0',
  },

  tableActionCell: {
    display: 'flex',
    flex: '0.2 !important',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  tableExtraSmallCell: {
    flex: '0.1 !important',
  },

  tableSmallCell: {
    flex: '0.3 !important',
  },

  tableMediumCell: {
    flex: '0.5 !important',
  },

  tableLargeCell: {
    flex: '0.7 !important',
  },
  tableNoteCell: {
    width: '10px',
    whiteSpace: 'wrap',
  },
});
