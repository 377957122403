import theme from '../../themes/theme';

const logoAndSocialsContainer: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
};

const followUsTextContainer: React.CSSProperties = {
  color: theme.palette.primary.main,
  fontFamily: theme.typography.h4.fontFamily,
  fontSize: theme.typography.h4.fontSize,
};

const iconStyle: React.CSSProperties = {
  margin: '0 1.5rem 0 0',
  fontSize: '2.5rem',
  color: theme.palette.primary.main,
  cursor: 'pointer',
  padding: 0,
  // @ts-ignore
  '&:hover': {
    color: theme.palette.primary.contrastText,
  },
};

const infoButtonsWrapperStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
};

const infoButtonsStyle: React.CSSProperties = {
  color: theme.palette.primary.contrastText,
  fontFamily: theme.typography.h4.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  padding: 0,
  margin: '0 0 0.5rem 0',
  // @ts-ignore
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
  },
};

const reservationTitleStyle: React.CSSProperties = {
  fontSize: theme.typography.h2.fontSize,
  fontFamily: theme.typography.h2.fontFamily,
  textTransform: 'uppercase',
  margin: '2rem 0 1.5rem 0',
  color: theme.palette.primary.contrastText,
};

const reservationDescriptionStyle: React.CSSProperties = {
  fontFamily: theme.typography.body1.fontFamily,
  fontSize: '0.9rem',
  margin: '0 0 2.5rem 0',
  color: theme.palette.primary.contrastText,
};

const reservationButtonStyle: React.CSSProperties = {
  borderRadius: 0,
  padding: '0.5rem 1rem 0.5rem 1rem',
  fontFamily: theme.typography.h4.fontFamily,
  fontSize: '1.1rem',
  // @ts-ignore
  '&:active': {
    transform: 'scale(0.98)',
  },
};

const styles: Record<string, React.CSSProperties> = {
  mobileWrapper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },

  contentWrapper: {
    display: 'flex',
  },

  companyInfoSection: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 2rem 0 0',
  },

  logoAndSocialsWrapper: {
    ...logoAndSocialsContainer,
    margin: '2rem 2rem 2rem 4rem',
    justifyContent: 'center',
    justifyItems: 'center',
    alignContent: 'center',
  },

  logoAndSocialsWrapperMobile: {
    ...logoAndSocialsContainer,
  },

  followUsText: {
    ...followUsTextContainer,
    margin: '3rem 0 0.5rem 0',
  },

  followUsTextMobile: {
    ...followUsTextContainer,
    fontSize: theme.typography.h5.fontSize,
    margin: '0.5rem 0 1rem 0',
  },

  icons: {
    ...iconStyle,
  },

  iconsMobile: {
    ...iconStyle,
    margin: '0 1rem 0 1rem',
    fontSize: '2.3rem',
  },

  companyInfoSectionMobile: {
    display: 'flex',
    justifyContent: 'center',
    margin: '1.5rem 0 0 0',
  },

  infoButtonsWrapper: {
    ...infoButtonsWrapperStyle,
    margin: '0 0 0 4rem',
    justifyContent: 'center',
  },

  infoButtonsWrapperMobile: {
    ...infoButtonsWrapperStyle,
  },

  infoButtons: {
    ...infoButtonsStyle,
    justifyContent: 'flex-start',
  },

  infoButtonsMobile: {
    ...infoButtonsStyle,
  },

  reservationWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0 4rem 0 auto',
  },

  reservationTitle: {
    ...reservationTitleStyle,
  },

  reservationTitleMobile: {
    ...reservationTitleStyle,
    fontSize: theme.typography.h4.fontSize,
    marginBottom: '0.5rem',
  },

  reservationDescription: {
    ...reservationDescriptionStyle,
  },

  reservationDescriptionMobile: {
    ...reservationDescriptionStyle,
    marginBottom: '2rem',
  },

  reservationButton: {
    ...reservationButtonStyle,
  },

  reservationButtonMobile: {
    ...reservationButtonStyle,
    margin: '0 0 3rem 0',
  },

  termsAndConditionsButton: {
    color: theme.palette.secondary.light,
    textAlign: 'right',
    fontSize: '0.75rem',
  },

  mobileTermsAndConditionsButton: {
    color: theme.palette.secondary.light,
    fontSize: '0.75rem',
    margin: '0 auto 1.5rem auto',
    width: 'fit-content',
  },

  creditMessage: {
    padding: 0,
    fontSize: '0.75rem',
    textAlign: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.light,
    fontFamily: theme.typography.fontFamily,
  },
  creditMessageIcon: {
    fontSize: '1.2rem',
    verticalAlign: 'middle',
  },
  creditMessageLink: {
    color: theme.palette.secondary.light,
    cursor: 'pointer',
    textDecoration: 'underline',
    fontFamily: theme.typography.fontFamily,
  },
};

export default styles;
