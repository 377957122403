import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteAccessToken, deleteBoAccessToken } from '../../../utils/utils';

interface IError {
  code: number | null;
  message: string;
  status: number | null;
  navigate?: (string) => void;
}

const initialState: {
  code: number | null;
  message: string | null;
  status: number | null;
} = {
  code: null,
  message: null,
  status: null,
};

const errorSlice = createSlice({
  name: 'ERROR_HANDLER',
  initialState,
  reducers: {
    handleNewErrorAction(state, { payload }) {
      state.code = payload.code;
      state.message = payload.message;
      state.status = payload.status;
    },
    clearErrorAction(state) {
      state.code = null;
      state.message = null;
      state.status = null;
    },
  },
});

export const { handleNewErrorAction, clearErrorAction } = errorSlice.actions;
export default errorSlice.reducer;

export const handleNewErrorThunk = createAsyncThunk('ERROR', async (errorObj: IError, { dispatch }) => {
  try {
    const { code, status } = errorObj;

    if (status === 401) {
      deleteAccessToken();
      deleteBoAccessToken();
      dispatch(handleNewErrorAction(errorObj));
    }

    switch (code) {
      case 102:
        try {
          deleteAccessToken();
          deleteBoAccessToken();
        } catch (e) {}
        break;
      default:
        dispatch(handleNewErrorAction(errorObj));
    }
  } catch (e) {}
});
