import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  mainWrapper: {
    width: '100%',
    marginTop: '1rem',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    paddingRight: 0,
    marginRight: 0,
  },

  refreshWrapper: {
    margin: '0 0 1rem 0',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '90%'
  },
});
