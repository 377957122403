import theme from '../../../themes/theme';
import React from 'react';

const mainContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  width: '30rem',
  height: '30rem',
  backgroundColor: theme.palette.secondary.dark,
  padding: '1rem',
};

const buttonStyle: React.CSSProperties = {
  height: '2.8rem',
  width: '9rem',
  borderRadius: '0.1rem',
  marginRight: '0.5rem',
  color: 'white',
  fontWeight: theme.typography.h1.fontWeight,
  letterSpacing: '0.05rem',
  textTransform: 'uppercase',
  // @ts-ignore
  '&:hover': {
    color: theme.palette.secondary.light,
    backgroundColor: 'transparent',
  },
  '&:active': {
    backgroundColor: 'transparent',
    color: theme.palette.secondary.light,
    transform: 'scale(0.98)',
  },
};

const buttonContainerStyle: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '2rem',
};

const styles: Record<string, React.CSSProperties> = {
  mainContainer: {
    ...mainContainerStyle,
  },
  mobileMainContainer: {
    ...mainContainerStyle,
    width: '20rem',
  },

  buttonContainer: {
    ...buttonContainerStyle,
  },

  mobileButtonContainer: {
    ...buttonContainerStyle,
    flexDirection: 'column',
  },

  confirmButton: {
    ...buttonStyle,
  },
  discardButton: {
    ...buttonStyle,
  },
  mobileConfirmButton: {
    ...buttonStyle,
    margin: '0.5rem 0 0.5rem 0',
    width: '15rem',
  },
  mobileDiscardButton: {
    ...buttonStyle,
    margin: '0.5rem 0 0.5rem 0',
    width: '15rem',
  },
};

export default styles;
