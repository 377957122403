import images from '../../../images/constants';
import { store } from '../../../store/store';
import { ICompanyServiceResourceResponseItem } from '../../../services/serviceTypes';
import { formatDateToHoursString, increaseForHourS } from '../../../utils/utils';

// @ts-ignore
export const getCompanyServiceResourcesMockData = (callback): ICompanyServiceResourceResponseItem[] => {
  // Subscribe to the store
  store.subscribe(() => {
    const state = store.getState();
    let data;
    if (state.companySelectedResources.value.name === 'Fudbal') {
      data = companyResourcesMockFootball;
    } else if (state.companySelectedResources.value.name === 'Tenis') {
      data = companyResourcesTennis;
    } else if (state.companySelectedResources.value.name === 'Košarka') {
      data = companyResourcesMockBasketball;
    } else if (state.companySelectedResources.value.name === 'Odbojka') {
      data = companyResourcesMockVolleyball;
    } else {
      data = companyResourcesMockFootball;
    }
    callback(data);
  });
};

export const companyServicesMock = [
  {
    id: '36c39e5a-0aee-4344-a3c7-6f26463ca1ba',
    name: 'Fudbal',
    description: 'Football fields booking',
    image_url: 'https://test.com/public/images/test.jpg',
    length_in_minutes: 60,
    price: '3500',
  },
  {
    id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    name: 'Tenis',
    description: 'Tennis fields booking',
    image_url: 'https://test.com/public/images/test.jpg',
    length_in_minutes: 60,
    price: '3500',
  },
  {
    id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bc',
    name: 'Košarka',
    description: 'Basketball fields booking',
    image_url: 'https://test.com/public/images/test.jpg',
    length_in_minutes: 60,
    price: '3500',
  },
];

export const companyResourcesMockFootball = [
  {
    id: '36c39e5a-0aee-4344-a3c7-6f26463ca101',
    name: 'Fudbal - Teren 1',
    description: 'Podloga: Veštačka trava - Teren unutra',
    image_url:
      'https://media.istockphoto.com/id/1403780865/photo/football-field-at-sunset.webp?b=1&s=170667a&w=0&k=20&c=bhRWE9HyitTFp4cRd9aAc5hY8L9VvWloBo8nSu-bWBA=',
  },
  {
    id: '36c39e5a-0aee-4344-a3c7-6f26463ca102',
    name: 'Fudbal - Teren 2',
    description: 'Podloga: Veštačka trava - Teren unutra',
    image_url: 'https://media.istockphoto.com/id/1403780865/photo/football-field-at-sunset.webp?b=1&s=170667a&w=0&k=20&c=bhRWE9HyitTFp4cRd9aAc5hY8L9VvWloBo8nSu-bWBA=',
  },
  {
    id: '36c39e5a-0aee-4344-a3c7-6f26463ca103',
    name: 'Fudbal - Teren 3',
    description: 'Podloga: Veštačka trava - Teren unutra',
    image_url: 'https://media.istockphoto.com/id/1403780865/photo/football-field-at-sunset.webp?b=1&s=170667a&w=0&k=20&c=bhRWE9HyitTFp4cRd9aAc5hY8L9VvWloBo8nSu-bWBA=',
  },
  {
    id: '36c39e5a-0aee-4344-a3c7-6f26463ca104',
    name: 'Fudbal - Teren 4',
    description: 'Podloga: Veštačka trava - Teren unutra',
    image_url: 'https://media.istockphoto.com/id/1403780865/photo/football-field-at-sunset.webp?b=1&s=170667a&w=0&k=20&c=bhRWE9HyitTFp4cRd9aAc5hY8L9VvWloBo8nSu-bWBA=',
  },
  {
    id: '36c39e5a-0aee-4344-a3c7-6f26463ca105',
    name: 'Fudbal - Teren 5',
    description: 'Podloga: Veštačka trava - Teren unutra',
    image_url: 'https://media.istockphoto.com/id/1403780865/photo/football-field-at-sunset.webp?b=1&s=170667a&w=0&k=20&c=bhRWE9HyitTFp4cRd9aAc5hY8L9VvWloBo8nSu-bWBA=',
  },
];

export const companyResourcesTennis = [
  {
    id: '36c39e5a-0aee-4344-a3c7-6f26463ca111',
    name: 'Tenis - Teren 1',
    description: 'Podloga: Šljaka - Teren napolju',
    image_url: 'https://test.com/public/images/test.jpg',
  },
  {
    id: '36c39e5a-0aee-4344-a3c7-6f26463ca1112',
    name: 'Tenis - Teren 2',
    description: 'Podloga: Šljaka - Teren napolju',
    image_url: 'https://test.com/public/images/test.jpg',
  },
  {
    id: '36c39e5a-0aee-4344-a3c7-6f26463ca113',
    name: 'Tenis - Teren 3',
    description: 'Podloga: Šljaka - Teren napolju',
    image_url: 'https://test.com/public/images/test.jpg',
  },
  {
    id: '36c39e5a-0aee-4344-a3c7-6f26463ca114',
    name: 'Tenis - Teren 4',
    description: 'Podloga: Šljaka - Teren napolju',
    image_url: 'https://test.com/public/images/test.jpg',
  },
  {
    id: '36c39e5a-0aee-4344-a3c7-6f26463ca120',
    name: 'Tenis - Teren 5',
    description: 'Podloga: US open beton - Teren unutra',
    image_url: 'https://test.com/public/images/test.jpg',
  },
];

export const companyResourcesMockBasketball = [
  {
    id: '36c39e5a-0aee-4344-a3c7-6f26463ca120',
    name: 'Košarka',
    description: 'US open beton - Unutra',
    image_url: 'https://test.com/public/images/test.jpg',
  },
];

export const companyResourcesMockVolleyball = [
  {
    id: '36c39e5a-0aee-4344-a3c7-6f26463ca120',
    name: 'Odbojka',
    description: 'US open beton - Unutra',
    image_url: 'https://test.com/public/images/test.jpg',
  },
];

const startDate = new Date().setHours(8, 0, 0, 0);
const stringDate = new Date(startDate).toISOString();

export const footballField01Slots = [
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca101',
    start_date: formatDateToHoursString(stringDate),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 1)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca101',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 1)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 2)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca101',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 2)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 3)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca101',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 3)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 4)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca101',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 4)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 5)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca101',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 5)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 6)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca101',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 6)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 7)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca101',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 7)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 8)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca101',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 8)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 9)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca101',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 9)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 10)),
    available: false,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca101',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 10)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 11)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca101',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 11)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 12)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca101',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 12)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 13)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca101',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 13)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 14)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca101',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 14)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 15)),
    available: false,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca101',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 15)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 16)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
];
export const footballField02Slots = [
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca102',
    start_date: formatDateToHoursString(stringDate),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 1)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca102',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 1)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 2)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca102',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 2)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 3)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca102',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 3)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 4)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca102',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 4)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 5)),
    available: false,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca102',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 5)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 6)),
    available: false,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca102',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 6)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 7)),
    available: false,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca102',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 7)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 8)),
    available: false,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca102',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 8)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 9)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca102',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 9)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 10)),
    available: false,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca102',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 10)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 11)),
    available: false,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca102',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 11)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 12)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca102',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 12)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 13)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca102',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 13)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 14)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca102',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 14)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 15)),
    available: false,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca102',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 15)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 16)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
];
export const footballField03Slots = [
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca103',
    start_date: formatDateToHoursString(stringDate),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 1)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca103',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 1)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 2)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca103',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 2)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 3)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca103',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 3)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 4)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca103',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 4)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 5)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca103',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 5)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 6)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca103',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 6)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 7)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca103',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 7)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 8)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca103',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 8)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 9)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca103',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 9)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 10)),
    available: false,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca103',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 10)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 11)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca103',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 11)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 12)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca103',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 12)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 13)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca103',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 13)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 14)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca103',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 14)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 15)),
    available: false,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca103',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 15)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 16)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
];
export const footballField04Slots = [
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca104',
    start_date: formatDateToHoursString(stringDate),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 1)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca104',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 1)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 2)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca104',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 2)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 3)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca104',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 3)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 4)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca104',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 4)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 5)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca104',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 5)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 6)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca104',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 6)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 7)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca104',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 7)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 8)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca104',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 8)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 9)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca104',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 9)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 10)),
    available: false,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca104',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 10)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 11)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca104',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 11)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 12)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca104',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 12)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 13)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca104',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 13)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 14)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca104',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 14)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 15)),
    available: false,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca104',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 15)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 16)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
];
export const footballField05Slots = [
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca105',
    start_date: formatDateToHoursString(stringDate),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 1)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca105',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 1)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 2)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca105',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 2)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 3)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca105',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 3)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 4)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca105',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 4)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 5)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca105',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 5)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 6)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca105',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 6)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 7)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca105',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 7)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 8)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca105',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 8)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 9)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca105',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 9)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 10)),
    available: false,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca105',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 10)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 11)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca105',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 11)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 12)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca105',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 12)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 13)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca105',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 13)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 14)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca105',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 14)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 15)),
    available: false,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
  {
    resource_id: '36c39e5a-0aee-4344-a3c7-6f26463ca105',
    start_date: formatDateToHoursString(increaseForHourS(stringDate, 15)),
    end_date: formatDateToHoursString(increaseForHourS(stringDate, 16)),
    available: true,
    appointment_id: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
    hash: '36c39e5a-0aee-4344-a3c7-6f26463ca1bb',
  },
];
