import React, { useMemo, useState } from 'react';

import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import srLatin from 'date-fns/locale/sr-Latn';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './styles.css';
import styles from './ScheduleAppointmentsCalendar.style';
import { text } from '../../../../../../../constants/constants';

const locales = {
  'sr-Latin': srLatin,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { locale: srLatin }),
  getDay,
  locales,
});

const messages = {
  today: 'Danas',
  previous: 'Prethodni dan',
  next: 'Sledeći dan',
  month: 'Mesec',
  week: 'Nedelja',
  day: 'Dan',
  agenda: 'Agenda',
  date: 'Datum',
  time: 'Vreme',
  event: 'Događaj',
  allDay: 'Ceo dan',
};

// Custom event display to include resource name
const CustomEvent = ({ event }) => {
  return (
    <div style={{ padding: '5px' }}>
      {event.title && <strong style={{ fontSize: '14px' }}>{event.title}</strong>}
      <br />
      <span style={{ fontSize: '12px' }}>{event.userPhoneNumber}</span>
      <br />
      <span style={{ fontSize: '14px' }}>{event.note}</span>
    </div>
  );
};

const CustomHeader = ({ resource }) => {
  return (
    <div style={styles.headerItems}>
      <strong>{resource.resourceTitle}</strong>
    </div>
  );
};

const CustomTimeGutterHeader = () => {
  return <div style={styles.headerLeftSideTitle}>{text.BO_CALENDAR.HEADER_TITLE}</div>;
};

// const CustomDateCell = ({ value }) => {
//   const [selectedSlice, setSelectedSlice] = useState(null);
//
//   const handleClick = (slice) => {
//     setSelectedSlice(slice);
//   };
//
//   return (
//     <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
//       <div
//         style={{
//           flex: 1,
//           borderBottom: '1px solid gray',
//           textAlign: 'center',
//           backgroundColor: selectedSlice === 'Slice 1' ? '#FFD700' : 'white', // Highlight Slice 1
//           cursor: 'pointer',
//         }}
//         onClick={() => handleClick('Slice 1')}
//       >
//         Slice 1
//       </div>
//       <div
//         style={{
//           flex: 1,
//           textAlign: 'center',
//           backgroundColor: selectedSlice === 'Slice 2' ? '#FFD700' : 'white', // Highlight Slice 2
//           cursor: 'pointer',
//         }}
//         onClick={() => handleClick('Slice 2')}
//       >
//         Slice 2
//       </div>
//     </div>
//   );
// };

const ScheduleAppointmentsCalendar = ({ appointments, onDateChange, onSelectSlotClick, onEventSlotClick }) => {
  const { containers } = appointments;
  const startWorkingHour = new Date(new Date().setHours(9, 0, 0));
  const endWorkingHour = new Date(new Date().setHours(23, 59, 0));

  const resources = useMemo(() => {
    const customContainers = containers.map((item) => ({
      resourceId: item.resource.id,
      resourceTitle: item.resource.name,
    }));

    // Move multi sport field od the index 5. Client requirement
    const lastElement = customContainers.pop();
    customContainers.splice(5, 0, lastElement);

    return customContainers;
  }, [containers]);

  const events = useMemo(() => {
    const customAppointments = [];
    containers.forEach((resource) => {
      if (resource?.appointments.length === 0) {
        return;
      }
      resource?.appointments?.forEach((appointment) => {
        const customEvent = {
          resourceId: resource.resource.id,
          resourceName: resource.resource.name,
          serviceId: appointment.service_id,
          appointmentId: appointment.id,
          userId: appointment.user?.id,
          userName: appointment.user?.first_name ? `${appointment.user?.first_name} ${appointment.user?.last_name}` : null,
          userPhoneNumber: appointment.user?.phone_number_international_format,
          title: appointment.user ? `${appointment.user?.first_name} ${appointment.user?.last_name}` : appointment.note,
          start: new Date(appointment.start_date),
          end: new Date(appointment.end_date),
          note: appointment.user ? appointment.note : null,
        };
        customAppointments.push(customEvent);
      });
    });
    return customAppointments;
  }, [containers]);

  const handleSelectSlot = (slotInfo) => {
    const selectedResource = containers.find((container) => container.resource.id === slotInfo.resourceId);
    const services = selectedResource ? selectedResource.services : [];

    const selectedSlotWithData = {
      ...slotInfo,
      resourceName: selectedResource.resource.name,
      services,
    };
    onSelectSlotClick(selectedSlotWithData);
  };

  const handleEventSlotClick = (slotInfo) => {
    const selectedResource = containers.find((container) => container.resource.id === slotInfo.resourceId);
    const services = selectedResource ? selectedResource.services : [];
    const selectedService = services.find((service) => service.id === slotInfo.serviceId);

    const selectedSlotWithData = {
      ...slotInfo,
      services,
      selectedService,
    };
    onEventSlotClick(selectedSlotWithData);
  };

  return (
    <div style={styles.calendarWrapper}>
      <Calendar
        localizer={localizer}
        events={events}
        resources={resources}
        resourceIdAccessor='resourceId'
        resourceTitleAccessor='resourceTitle'
        defaultView='day' // Use "day" or "week"
        views={['day', 'week']} // Allow day and week views
        components={{
          event: CustomEvent,
          resourceHeader: CustomHeader,
          timeGutterHeader: CustomTimeGutterHeader,
        }}
        // style={{ height: '120vh', overflowY: 'scroll' }}
        min={startWorkingHour}
        max={endWorkingHour}
        step={60} // 60-minute time slots
        timeslots={1} // 1 slots per hour (for finer granularity)
        selectable={true}
        onNavigate={onDateChange}
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleEventSlotClick}
        longPressThreshold={400}
        culture='sr-Latin'
        messages={messages}
      />
    </div>
  );
};

export default ScheduleAppointmentsCalendar;
