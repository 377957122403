import { getStyles } from '../../components/SunriseInfoPage/SunriseInfoPage.style';
import theme from '../../../themes/theme';

const parentStyles = (isMobile: boolean): Record<string, React.CSSProperties> => {
  const styles = getStyles(isMobile);

  return {
    wrapper: {
      ...styles.parentWrapper,
    },

    title: {
      ...styles.parentTitle,
    },

    content: {
      ...styles.parentContent,
    },

    termWrapper: {
      marginTop: 50,
    },
    titleText: {
      fontWeight: theme.typography.h1.fontWeight,
      fontSize: theme.typography.h3.fontSize,
    },
    descriptionText: {
      fontSize: theme.typography.body1.fontSize,
    },
  };
};

export default parentStyles;
