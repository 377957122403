import React, { useEffect, useRef } from 'react';
import styles from './SunriseHomePage.style';
import images from '../../images/constants';
import { text } from '../../constants';
import { clientInfo } from '../../../constants/constants';
import BookitButton from '../../../components/foundation/BookitButton/BookitButton';
import BookitMap from '../../../components/BookitMap/BookitMap';
import BookitIcon from '../../../components/foundation/BookitIcon/BookitIcon';
import SunriseHeader from '../../components/SunriseHeader/SunriseHeader';
import FooterAdvanced from '../../../components/Footer/FooterAdvanced';
import { TIcon } from '../../../types';
import { useLocation, useNavigate } from 'react-router';

const SunriseHomePage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const contactRef = useRef(null);

  useEffect(() => {
    if (state?.scrollTo === 'contact') {
      navigate('.', { state: null });
      contactRef?.current?.scrollIntoView();
    }
  }, [state, navigate]);
  const renderMoreDetails = (textColor: string, path: string) => (
    <div style={styles.seeMoreButtonWrapper}>
      <BookitButton
        type='routerLink'
        variant='text'
        routerPath={path}
        label={text.SEE_MORE}
        style={{ ...styles.seeMoreButton, color: textColor }}
        disableRipple
      />
    </div>
  );

  const renderWelcomeBlock = () => {
    return (
      <>
        <div style={styles.welcomeWrapper}>
          <div style={{ zIndex: 1 }}>
            <SunriseHeader isTransparent={true} />
          </div>
          <img src={images.FOOTBALL_FIELD_02} alt='Aerial view of the sport center "Sunrise"' style={styles.welcomeImage} />
          <div style={styles.welcomeTextWrapper}>
            <h1 style={styles.welcomeTitle}>{text.WELCOME.TITLE}</h1>
            <h3>{text.WELCOME.SUBTITLE}</h3>
          </div>
        </div>
      </>
    );
  };

  const renderAboutUs = () => {
    return (
      <div style={styles.aboutUsWrapper}>
        <div style={styles.aboutUsLeftSideContentWrapper}>
          <div style={styles.aboutUsTextWrapper}>
            <h2 style={styles.aboutUsTitle}>{text.ABOUT_US.TITLE}</h2>
            <p style={styles.aboutUsSubtitle}>{text.ABOUT_US.SHORT_DSC}</p>
          </div>
          {renderMoreDetails('white', 'about-us')}
        </div>
        <div style={styles.aboutUsRightSideContentWrapper}>
          <img src={images.ABOUT_US_TENNIS} alt='Tennis ball on a clay court' style={styles.aboutUsImage} />
        </div>
      </div>
    );
  };

  const renderLeagues = () => {
    return (
      <div style={styles.leaguesWrapper}>
        <h2 style={styles.leaguesTitle}>{text.LEAGUES.TITLE}</h2>
        <div style={styles.leaguesContentWrapper}>
          <img src={images.LEAGUES1} alt='Four boys playing a football game' style={styles.leaguesImages} />
          <div style={styles.leaguesTextWrapper}>
            <h3 style={styles.leaguesContentsTitle}>{text.LEAGUES.LEAGUE_02_TITLE}</h3>
            <p style={styles.leaguesContentsSubtitle}>{text.LEAGUES.LEAGUE_02_SHORT_DSC}</p>
            {renderMoreDetails('black', 'leagues')}
          </div>
          <img src={images.LEAGUES2} alt='Football grass field lines' style={styles.leaguesImages} />
        </div>
        <div style={{ ...styles.leaguesContentWrapper, marginBottom: '5rem' }}>
          <div style={styles.leaguesTextWrapper}>
            <h3 style={styles.leaguesContentsTitle}>{text.LEAGUES.LEAGUE_01_TITLE}</h3>
            <p style={styles.leaguesContentsSubtitle}>{text.LEAGUES.LEAGUE_01_SHORT_DSC}</p>
            {renderMoreDetails('black', 'leagues')}
          </div>
          <img src={images.LEAGUES3} alt='Tennis racket with a ball on it' style={styles.leaguesImages} />
          <div style={styles.leaguesTextWrapper}>
            <h3 style={styles.leaguesContentsTitle}>{text.LEAGUES.LEAGUE_03_TITLE}</h3>
            <p style={styles.leaguesContentsSubtitle}>{text.LEAGUES.LEAGUE_03_SHORT_DSC}</p>
            {renderMoreDetails('black', 'leagues')}
          </div>
        </div>
      </div>
    );
  };

  const renderCallUsButton = () => (
    <BookitButton
      variant='outlined'
      label='Pozovite'
      type='basic'
      style={styles.schoolsButton}
      onClick={() => (window.location.href = `tel: ${text.PHONE_NUMBER}`)}
    >
      <BookitIcon type='longRightArrow' style={styles.schoolsButtonIcon} />
    </BookitButton>
  );

  const renderSchools = () => {
    return (
      // main wrapper
      <div style={styles.schoolsWrapper}>
        {/* football school wrapper */}
        <div style={styles.schoolWrapper}>
          <div style={styles.footballSchoolLeftSideContentWrapper}>
            <img
              src={images.SCHOOL1}
              alt='Football team of boys wearing medals around their necks celebrating with two coaches with their hands in the air'
              style={styles.schoolImage}
            />
          </div>

          <div style={styles.footballSchoolRightSideContentWrapper}>
            <div style={styles.schoolContentWrapper}>
              <div>
                <h2 style={styles.schoolTitle}>{text.FOOTBALL_SCHOOL.TITLE}</h2>
                <p style={styles.schoolDescription}>{text.FOOTBALL_SCHOOL.SC_SHORT_DSC}</p>
                {renderMoreDetails('white', 'football')}
              </div>
              {renderCallUsButton()}
            </div>
          </div>
        </div>

        {/* tennis school wrapper */}
        <div style={styles.schoolWrapper}>
          <div style={styles.tennisSchoolLeftSideContentWrapper}>
            <div style={styles.schoolContentWrapper}>
              <div>
                <h2 style={styles.schoolTitle}>{text.TENNIS_SCHOOL.TITLE}</h2>
                <p style={styles.schoolDescription}>{text.TENNIS_SCHOOL.SHORT_DSC}</p>
                {renderMoreDetails('white', 'tennis')}
              </div>
              {renderCallUsButton()}
            </div>
          </div>
          <div style={styles.tennisSchoolRightSideContentWrapper}>
            <img src={images.SCHOOL3} alt='Girl playing tennis on a clay court' style={styles.schoolImage} />
          </div>
        </div>
      </div>
    );
  };

  const renderRecreationalSports = () => {
    return (
      <div style={styles.recreationalSportsWrapper}>
        <div style={styles.recreationalSportsLeftSideContent}>
          <h2 style={styles.recreationalSportsTitle}>{text.RECREATIONAL_SPORT.TITLE}</h2>
          <p style={styles.recreationalSportsSubtitle}>{text.RECREATIONAL_SPORT.SHORT_DSC}</p>
          {renderMoreDetails('black', 'recreational-sport')}
          <BookitButton
            variant='outlined'
            label={text.PRICING_BUTTON}
            type='routerLink'
            routerPath='/pricing'
            style={styles.pricingButton}
          >
            <BookitIcon type='longRightArrow' style={styles.pricingButtonIcon} />
          </BookitButton>
        </div>

        <div style={styles.recreationalSportsRightSideContent}>
          {['BALL1', 'BALL2', 'BALL3', 'BALL4'].map((ball, index) => (
            <div key={index} style={styles.recreationalSportsImageWrapper}>
              <img
                src={images[ball]}
                alt='A football, basketball, tennis and volleyball ball'
                style={styles.recreationalSportsImages}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const contactDetails: { type: TIcon; title: string; subtitle: string; href: string }[] = [
    {
      type: 'mapPin',
      title: `${text.CONTACT_CARD.ADDRESS_TITLE}`,
      subtitle: `${text.CONTACT_CARD.ADDRESS_SUBTITLE}`,
      href: `https://www.google.com/maps/place/%D0%A1%D0%B0%D0%BD+%D0%A0%D0%B0%D1%98%D1%81/@44.7561987,19.6721605,17z/data=!3m1!4b1!4m6!3m5!1s0x475bca54c631499d:0x1aecbdbcf1bdbf1e!8m2!3d44.7561949!4d19.6747354!16s%2Fg%2F11bbrkbtsh?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D${encodeURIComponent(
        text.CONTACT_CARD.ADDRESS_SUBTITLE,
      )}`,
    },
    {
      type: 'phone',
      title: `${text.CONTACT_CARD.PHONE_TITLE}`,
      subtitle: `${text.CONTACT_CARD.PHONE_SUBTITLE}`,
      href: `tel:${text.CONTACT_CARD.PHONE_SUBTITLE}`,
    },
    {
      type: 'email',
      title: `${text.CONTACT_CARD.EMAIL_TITLE}`,
      subtitle: `${text.CONTACT_CARD.EMAIL_SUBTITLE}`,
      href: `mailto:${text.CONTACT_CARD.EMAIL_SUBTITLE}`,
    },
  ];

  const renderContactDetails = () => {
    return (
      <div ref={contactRef} style={styles.contactDetailsWrapper}>
        <h2 style={styles.contactDetailsTitle}>{text.CONTACT_DETAILS_TITLE}</h2>

        <div style={styles.contactCard}>
          <div style={styles.contactCardWrapper}>
            {contactDetails.map(({ type, title, subtitle, href }) => (
              <a href={href} target='_blank' rel='noopener noreferrer' key={title} style={styles.contactCardInfoContainer}>
                <div style={styles.contactCardIconAndTitleWrapper}>
                  <BookitIcon type={type} style={styles.contactCardIcons} />
                  <p style={styles.contactCardInfoContainerTitle}>{title}</p>
                </div>
                <p style={styles.contactCardInfoContainersubtitle}>{subtitle}</p>
              </a>
            ))}
          </div>
        </div>

        <div style={styles.mapWrapper}>
          <BookitMap
            position={{ lat: clientInfo.LOCATION.LATITUDE, lng: clientInfo.LOCATION.LONGITUDE }}
            popup={text.MAP_MARKER_POPUP}
          />
        </div>
        <img src={images.WELCOME_FIELD} alt='Aerial view of the sport center "Sunrise"' style={styles.contactDetailsImage} />
      </div>
    );
  };

  const renderFooter = () => <FooterAdvanced />;

  return (
    <div style={styles.wrapper}>
      {renderWelcomeBlock()}
      {renderAboutUs()}
      {renderLeagues()}
      {renderSchools()}
      {renderRecreationalSports()}
      {renderContactDetails()}
      {renderFooter()}
    </div>
  );
};

export default SunriseHomePage;
