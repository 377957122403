import React, { useEffect, useState } from 'react';
import { getBoAppointmentsThunkByDate } from '../../redux/boAppointmentsSlice';
import { formatDateToHoursString, formatDateToYearMonthDay } from '../../../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../../types';
import { AppDispatch } from '../../../../../store/store';
import { useStyles } from './BackOfficeAllAppointmentsTable.style';
import BookitTable from '../../../../../components/foundation/BookitTable/BookitTable';
import BookitCalendar from '../../../../../components/foundation/BookitCalendar/BookitCalendar';
import { addDays } from 'date-fns';
import { text } from '../../../../../constants/constants';
import BookitButton from '../../../../../components/foundation/BookitButton/BookitButton';
import BookitTooltip from '../../../../../components/foundation/BookitTooltip/BookitTooltip';

interface ITableDataItem {
  columnName: string;
  value: string | JSX.Element;
  hidden?: boolean;
  style?: React.CSSProperties;
  status?: {
    columnName: string;
    content: string;
    value?: string;
  };
}

interface IStatusOption {
  value: string;
  label: string;
}

const BackOfficeAllAppointmentsTable = () => {
  const styles = useStyles();

  const [tableData, setTableData] = useState<ITableDataItem[]>([]);
  const [filteredData, setFilteredData] = useState<ITableDataItem[]>([]);
  const [from, setFrom] = useState<Date>(new Date());
  const [to, setTo] = useState<Date>(addDays(from, 30));
  const [statusFilter, setStatusFilter] = useState<string>('all');

  const appointments = useSelector((state: IStore) => state.backOfficeAppointments);
  const dispatch = useDispatch<AppDispatch>();

  const searchAvailable = ['userFullName', 'note'];

  useEffect(() => {
    dispatch(getBoAppointmentsThunkByDate({ from, to }));
  }, [from, to]);

  useEffect(() => {
    if (appointments?.allAppointments) {
      const mappedTableData = tableDataMap(appointments.allAppointments);
      setTableData(mappedTableData);
    }
  }, [appointments, from, to]);

  useEffect(() => {
    const filtered =
      statusFilter === 'all'
        ? tableData
        : tableData.filter((item) => item.status.value.toLowerCase().includes(statusFilter.toLowerCase()));
    setFilteredData(filtered);
  }, [tableData, statusFilter]);

  const tableDataMap = (initialData) => {
    return initialData.map((item, index) => {
      return {
        id: { columnName: item.id, content: item.id, hidden: true },
        no: { columnName: '#', content: index + 1, style: styles.tableExtraSmallCell },
        date: { columnName: 'Datum', content: formatDateToYearMonthDay(item.start_date), style: styles.tableMediumCell },
        time: { columnName: 'Vreme', content: formatDateToHoursString(item.start_date), style: styles.tableSmallCell },
        serviceName: { columnName: 'Sport', content: item.service.name, style: styles.tableMediumCell },
        resourceName: { columnName: 'Teren', content: item.resource.name, style: styles.tableLargeCell },
        userFullName: {
          columnName: 'Ime i prezime',
          content: item.retail_user?.first_name ? `${item.retail_user?.first_name} ${item.retail_user?.last_name}` : '-',
        },
        userPhoneNumber: {
          columnName: 'Broj telefona',
          content: item.retail_user?.phone_number.international_format
            ? `${item.retail_user?.phone_number.international_format}`
            : '-',
          style: styles.tableLargeCell,
        },
        userEmail: {
          columnName: 'Email',
          content: item.retail_user?.email ? (
            <BookitTooltip title={item.retail_user?.email} placement='top' followCursor>
              <span style={{ cursor: 'pointer' }}>{item.retail_user?.email}</span>
            </BookitTooltip>
          ) : (
            '-'
          ),
          style: styles.tableTruncatedCell,
        },
        note: { columnName: 'Beleška', content: `${item.note}`, style: styles },
        status: {
          columnName: 'Status',
          content: (
            <BookitTooltip title={item.status} placement='top' followCursor>
              <span style={{ cursor: 'pointer' }}>{item.status}</span>
            </BookitTooltip>
          ),
          style: styles.tableTruncatedCell,
          value: item.status,
        },
      };
    });
  };

  const renderCalendarPickers = () => (
    <div className={styles.calendarWrapper}>
      <div style={{ marginRight: '1rem' }}>
        <BookitCalendar label={'Od'} selectedDate={from} setSelectedDate={(date) => setFrom(date)} />
      </div>
      <BookitCalendar label={'Do'} selectedDate={to} setSelectedDate={(date) => setTo(date)} />
    </div>
  );

  const renderStatusFilters = () => {
    const statusOptions: IStatusOption[] = [
      { value: 'all', label: 'All' },
      { value: 'approved', label: 'Approved' },
      { value: 'canceled_by_user', label: 'Canceled by User' },
      { value: 'canceled_by_admin', label: 'Canceled by Admin' },
      { value: 'blocked_by_admin', label: 'Blocked by Admin' },
    ];

    return (
      <div style={{ marginBottom: '0.5rem' }}>
        {statusOptions.map(({ value, label }) => (
          <BookitButton
            key={value}
            type='basic'
            label={label}
            onClick={() => setStatusFilter(value)}
            variant={statusFilter === value ? 'contained' : 'outlined'}
            style={{ marginRight: '0.5rem' }}
          />
        ))}
      </div>
    );
  };

  return (
    <div className={styles.mainWrapper}>
      {renderCalendarPickers()}
      {renderStatusFilters()}
      <div className={styles.tableWrapper}>
        {filteredData.length > 0 ? (
          <BookitTable tableData={filteredData} searchAvailable={searchAvailable} />
        ) : (
          <p className={styles.noData}>{text.BO_NO_DATA}</p>
        )}
      </div>
    </div>
  );
};

export default BackOfficeAllAppointmentsTable;
