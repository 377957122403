import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import { IOngoingReservation, IStore } from '../../../../types';
import { getCompanyServicesThunk } from '../../redux/companyServicesSlice';
import { setCompanyResourceSlotsThunk, setCompanyResourcesSlotsForProvidedDate } from '../../redux/companySlotsSlice';
import { setCompanySelectedServiceDataThunk } from '../../redux/companySelectedResourcesSlice';
import {
  addOngoingReservationDateAction,
  addOngoingReservationFieldAction,
  addOngoingReservationHashAction,
  clearOngoingReservationAction,
  handleNewReservationThunk,
  setReservationPriceThunk,
} from '../../redux/ongoingReservationSlice';

import DayPickerCalendar from '../../../../components/foundation/BookitCalendar/DayPickerCalendar';
import BookitButton from '../../../../components/foundation/BookitButton/BookitButton';
import BookitTabs from '../../../../components/foundation/BookitTabs/BookitTabs';

import ConfirmReservationDrawer from '../../../../components/ConfirmReservationDrawer/ConfirmReservationDrawer';
import FieldCard from '../../../../components/FieldCard/FieldCard';
import Header from '../../../../components/Header/Header';
import { text } from '../../../../constants/constants';
import { formatDateToYearMonthDay, isBoUserLoggedIn, isUserLoggedIn } from '../../../../utils/utils';

import styles from './HomePage.style';
import { useNavigate } from 'react-router';
import { useIsMobile } from '../../../../hooks/IsMobileHook';
import BookitSnackbar from '../../../../components/foundation/BookitSnackbar/BookitSnackbar';
import ConfirmAccountAlertBox from '../../../../components/ConfirmAccountAlertBox/ConfirmAccountAlertBox';
import FooterAdvanced from '../../../../components/Footer/FooterAdvanced';
import { handleNewErrorThunk } from '../../../App/redux/errorSlice';

const HomePage = () => {
  const [isConfirmReservationDrawerOpen, setIsConfirmationReservationDrawerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [date, setDate] = useState(new Date());
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const profile = useSelector((store: IStore) => store.profile.data);

  const time = useSelector((store: IStore) => store.ongoingReservation.time);
  const hash = useSelector((store: IStore) => store.ongoingReservation.hash);
  const note = useSelector((store: IStore) => store.ongoingReservation.note);
  const bookedField = useSelector((store: IStore) => store.ongoingReservation.fieldReservation);

  const companyServices = useSelector((store: IStore) => store.companyServices.value);
  const selectedResources = useSelector((store: IStore) => store.companySelectedResources.value);
  const resourceSlots = useSelector((store: IStore) => store.companyResourcesSlots.value);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const ongoingReservationData: IOngoingReservation = {
    fieldReservation: bookedField,
    time: time,
    date: date,
    hash: hash,
    note: note,
    serviceId: companyServices && companyServices.length && companyServices[activeTab].id,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get all services
  useEffect(() => {
    const fetchService = async () => {
      try {
        await dispatch(getCompanyServicesThunk()).unwrap();
      } catch (e) {
        dispatch(handleNewErrorThunk({ ...e, navigate, navigateTo: '/login' }));
      }
    };
    fetchService();
  }, [dispatch, navigate]);

  // Get resources for selected service
  useEffect(() => {
    if (!companyServices) {
      return;
    }
    const selectedCompanyService = {
      id: companyServices[activeTab].id,
      name: companyServices[activeTab].name,
      description: companyServices[activeTab].description,
    };
    try {
      dispatch(setCompanySelectedServiceDataThunk(selectedCompanyService)).unwrap();
      dispatch(
        setCompanyResourceSlotsThunk({
          serviceId: selectedCompanyService.id,
          from: date,
          to: date,
        }),
      ).unwrap();
    } catch (e) {}
  }, [activeTab, companyServices, date, dispatch]);

  // Get slots filtered by resources of selected service
  useEffect(() => {
    try {
      dispatch(setCompanyResourcesSlotsForProvidedDate(formatDateToYearMonthDay(date)));
    } catch (e) {}
  }, [date, resourceSlots.allSlots]);

  // // If user have some draft reservation, open drawer on mount.
  // useEffect(() => {
  //   if (bookedField?.name) {
  //     openDrawer();
  //   }
  // }, []);

  const getOneResourceSlots = useCallback(
    (resourceId) => {
      return resourceSlots?.selectedDateSlots?.filter((slot) => {
        return slot.resource_id === resourceId;
      });
    },
    [resourceSlots],
  );

  // const openDrawer = () => {
  //   setIsConfirmationReservationDrawerOpen(true);
  // };
  const closeDrawer = () => {
    setIsConfirmationReservationDrawerOpen(false);
    dispatch(clearOngoingReservationAction());
  };
  const toggleDrawer = () => {
    setIsConfirmationReservationDrawerOpen((prevState) => !prevState);
  };

  const handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleDateClick = (date) => {
    setDate(date);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleTimeSlotClick = async (field, time, hash) => {
    dispatch(addOngoingReservationFieldAction(field));
    dispatch(setReservationPriceThunk(time));
    dispatch(addOngoingReservationHashAction(hash));
    dispatch(addOngoingReservationDateAction(formatDateToYearMonthDay(date)));

    setIsConfirmationReservationDrawerOpen(true);
  };

  const handleSubmit = async () => {
    if (isUserLoggedIn()) {
      try {
        await dispatch(handleNewReservationThunk(ongoingReservationData)).unwrap();
        toggleDrawer();
        setSnackbarOpen(true);
        return;
      } catch (e) {
        dispatch(handleNewErrorThunk(e));
      }
    }
    navigate('/login', { state: { fromSubmitReservation: true } });
    dispatch(clearOngoingReservationAction());
  };

  const renderDayPickerCalendar = () => <DayPickerCalendar selectedDate={date} setSelectedDate={handleDateClick} />;

  const renderTabs = () => {
    if (!companyServices) {
      return;
    }
    const tabs = companyServices.map((sport) => {
      return {
        label: sport.name,
      };
    });

    return (
      <div style={styles.tabsContainer}>
        <BookitTabs tabs={tabs} value={activeTab} onChange={handleTabChange} />
      </div>
    );
  };

  const renderTabContent = () => {
    if (!selectedResources) {
      return;
    }

    if (!selectedResources.resources?.length) {
      return;
    }
    return (
      <div style={styles.fields}>
        {selectedResources.resources.map((field) => (
          <FieldCard
            key={field.id}
            field={field}
            onTimeSlotClick={handleTimeSlotClick}
            bookedField={bookedField}
            selectedDate={date}
            selectedTime={time}
            slots={getOneResourceSlots(field.id)}
          />
        ))}
        <BookitSnackbar open={snackbarOpen} text={text.HOME.SUCCESS_RESERVATION_MESSAGE} onClose={handleSnackbarClose} />
      </div>
    );
  };

  const renderHeader = () => <Header />;
  const renderDivider = () => <hr style={styles.divider} />;

  const renderConfirmationDrawer = () => (
    <ConfirmReservationDrawer
      isOpen={isConfirmReservationDrawerOpen}
      onClose={closeDrawer}
      selectedDate={date}
      selectedTime={time}
      bookedField={bookedField}
      onConfirm={handleSubmit}
    />
  );

  const renderFooter = () => <FooterAdvanced />;

  return (
    <div style={styles.wrapper}>
      <div style={styles.headerWrapper}>{renderHeader()}</div>
      <div style={styles.contentWrapper}>
        <div
          style={{
            ...styles.contentContainer,
            ...(isMobile && styles.mobileContentContainer),
          }}
        >
          {renderTabs()}
          {renderDayPickerCalendar()}
          {profile.verified_account === false && <ConfirmAccountAlertBox />}
          {renderDivider()}
          {renderTabContent()}
        </div>
        {renderConfirmationDrawer()}
        <div style={styles.footerWrapper}>{renderFooter()}</div>
      </div>
    </div>
  );
};

export default HomePage;
