import React from 'react';
import { text } from '../../constants/constants';
import { IReservation, IStore } from '../../types';
import styles from './CancelReservationDialog.style';
import BookitDialogAdvanced from '../../components/foundation/BookitDialog/BookitDialogAdvanced';
import images from '../../images/constants';
import { useSelector } from 'react-redux';

interface IProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (reservation: IReservation) => void;
  children: React.ReactNode;
}

const CancelReservationDialog = ({ open, onClose, onConfirm, children }: IProps) => {
  const { loading: userLoading } = useSelector((store: IStore) => store.reservationForCancel);
  const { loading: boLoading } = useSelector((store: IStore) => store.backOfficeAppointments);
  return (
    <BookitDialogAdvanced open={open} onClose={onClose} onConfirm={onConfirm} loading={userLoading || boLoading}>
      <div style={styles.imageWrapper}>
        <img src={images.CANCEL_RESERVATION} alt='Orange card broken in half' style={styles.image} />
      </div>
      <p style={styles.title}>{text.DIALOG_TEXT.CONFIRM_CANCEL_DIALOG_TITLE}</p>
      {children}
    </BookitDialogAdvanced>
  );
};

export default CancelReservationDialog;
