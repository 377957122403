import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteBoAccessToken, setBoAccessToken } from '../../../../utils/utils';
import ApiService from '../../../../services/api-service';

interface IPayload {
  email: string;
  password: string;
}

const initialState: { error: string | null; loading: boolean } = {
  error: null,
  loading: false,
};

const boAuthSlice = createSlice({
  name: 'BACK_OFFICE_AUTH',
  initialState,
  reducers: {
    showAuthErrorAction(state, action) {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPasswordBoThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(forgotPasswordBoThunk.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(forgotPasswordBoThunk.rejected, (state, action) => {
        state.loading = false;
        // @ts-ignore
        state.error = action?.payload || 'eer'; // Error message passed via rejectWithValue
      })
      .addCase(loginBoThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginBoThunk.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(loginBoThunk.rejected, (state, action) => {
        state.loading = false;
        // @ts-ignore
        state.error = action?.payload || 'eer'; // Error message passed via rejectWithValue
      })
      .addCase(logoutBoThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logoutBoThunk.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(logoutBoThunk.rejected, (state, action) => {
        state.loading = false;
        // @ts-ignore
        state.error = action?.payload || 'eer'; // Error message passed via rejectWithValue
      });
  },
});
export const { showAuthErrorAction } = boAuthSlice.actions;
export default boAuthSlice.reducer;

// Async thunks
export const loginBoThunk = createAsyncThunk('BACK_OFFICE_LOGIN', async (payload: IPayload, { dispatch, rejectWithValue }) => {
  try {
    deleteBoAccessToken();
    const token = await ApiService?.getBoAuthService()?.loginBoEmailAndPassword(payload.email, payload.password);
    setBoAccessToken(token);
  } catch (error) {
    return rejectWithValue({
      code: error.code,
      message: error.message,
      status: error.status,
    });
  }
});

export const forgotPasswordBoThunk = createAsyncThunk(
  'BACK_OFFICE_FORGOT_PASSWORD',
  async (payload: string, { rejectWithValue }) => {
    try {
      await ApiService?.getBoAuthService()?.forgotPasswordBo(payload);
    } catch (error) {
      return rejectWithValue({
        code: error.code,
        message: error.message,
        status: error.status,
      });
    }
  },
);

export const changePasswordBoThunk = createAsyncThunk(
  'BACK_OFFICE_CHANGE_PASSWORD',
  async (payload: string, { rejectWithValue }) => {
    try {
      await ApiService?.getBoAuthService()?.changePasswordBo(payload);
    } catch (error) {
      return rejectWithValue({
        code: error.code,
        message: error.message,
        status: error.status,
      });
    }
  },
);

export const logoutBoThunk = createAsyncThunk('BACK_OFFICE_LOGOUT', async (payload, { dispatch, rejectWithValue }) => {
  try {
    await ApiService?.getBoAuthService()?.logoutBo();
    deleteBoAccessToken();
  } catch (error) {
    return rejectWithValue({
      code: error.code,
      message: error.message,
      status: error.status,
    });
  }
});
