import React from 'react';
import BookitFooterAdvanced from '../foundation/BookitFooter/BookitFooterAdvanced';
import SunriseLogo from '../../customerComponents/components/SunriseLogo/SunriseLogo';
import { links, text } from '../../constants/constants';
import BookitButton from '../foundation/BookitButton/BookitButton';
import styles from './FooterAdvanced.style';
import BookitIcon from '../foundation/BookitIcon/BookitIcon';
import { useIsMobile } from '../../hooks/IsMobileHook';
import { useNavigate, useLocation } from 'react-router';
import SunriseNavigationButtons from '../../customerComponents/components/SunriseNavigationButtons/SunriseNavigationButtons';
import featureFlags from '../../featureFlags';

const FooterAdvanced = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const showReservationButton = pathname !== '/reservations' && featureFlags.showReservationsButton;

  const handleReserveButtonClick = () => {
    navigate('/reservations');
  };

  const renderCreditMessage = () => {
    return (
      <p style={styles.creditMessage}>
        Made with <BookitIcon type='heart' style={styles.creditMessageIcon} /> by{' '}
        <a href='mailto:office@brotech.rs' target='_blank' rel='noopener noreferrer' style={styles.creditMessageLink}>
          brotech.
        </a>
      </p>
    );
  };

  const renderDesktopView = () => {
    return (
      <div style={{ width: '100%' }}>
        <div style={styles.contentWrapper}>
          {/* left side */}
          <div style={styles.companyInfoSection}>
            <div style={styles.logoAndSocialsWrapper}>
              <div style={{ display: 'flex' }}>
                <SunriseLogo size='large' isLogoWhite />
              </div>
              <p style={styles.followUsText}>{text.FOOTER.FOLLOW_US_TEXT}</p>
              <div>
                <a href={links.FACEBOOK_LINK} target='_blank' title={text.ABOUT_US.FACEBOOK_TITLE} rel='noreferrer'>
                  <BookitIcon type='facebook' style={styles.icons} />
                </a>
                <a href={links.INSTAGRAM_LINK} target='_blank' title={text.ABOUT_US.INSTAGRAM_TITLE} rel='noreferrer'>
                  <BookitIcon type='instagram' style={styles.icons} />
                </a>
              </div>
            </div>
            <SunriseNavigationButtons />
          </div>
          {/* right side */}
          <div style={styles.reservationWrapper}>
            <div>
              <p style={styles.reservationTitle}>{text.FOOTER.RESERVATION_TITLE}</p>
              <p style={styles.reservationDescription}>{text.FOOTER.RESERVATION_DESCRIPTION}</p>
              {showReservationButton && (
                <BookitButton
                  variant='contained'
                  label={text.FOOTER.RESERVATION_BUTTON}
                  type='basic'
                  style={styles.reservationButton}
                  disableRipple
                  onClick={handleReserveButtonClick}
                />
              )}
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', margin: '2rem 4rem 0 4rem', justifyContent: 'space-between', fontSize: '0.75rem' }}>
          {renderCreditMessage()}
          <BookitButton
            variant='text'
            label={text.FOOTER.TERMS_AND_CONDITIONS_BUTTON}
            type='routerLink'
            routerPath='/terms-and-conditions'
            style={styles.termsAndConditionsButton}
          />
        </div>
      </div>
    );
  };
  const renderMobileView = () => {
    return (
      <div style={styles.mobileWrapper}>
        <div style={styles.logoAndSocialsWrapperMobile}>
          <SunriseLogo size='medium' isLogoWhite />
          <p style={styles.followUsTextMobile}>{text.FOOTER.FOLLOW_US_TEXT}</p>
          <div>
            <a href={links.FACEBOOK_LINK} target='_blank' title={text.ABOUT_US.FACEBOOK_TITLE} rel='noreferrer'>
              <BookitIcon type='facebook' style={styles.iconsMobile} />
            </a>
            <a href={links.INSTAGRAM_LINK} target='_blank' title={text.ABOUT_US.INSTAGRAM_TITLE} rel='noreferrer'>
              <BookitIcon type='instagram' style={styles.iconsMobile} />
            </a>
          </div>
        </div>
        <div style={styles.companyInfoSectionMobile}>
          <SunriseNavigationButtons />
        </div>
        <div>
          <p style={styles.reservationTitleMobile}>{text.FOOTER.RESERVATION_TITLE}</p>
          <p style={styles.reservationDescriptionMobile}>{text.FOOTER.RESERVATION_DESCRIPTION}</p>
          {showReservationButton && (
            <BookitButton
              variant='contained'
              label={text.FOOTER.RESERVATION_BUTTON}
              type='basic'
              style={styles.reservationButtonMobile}
              disableRipple
              onClick={handleReserveButtonClick}
            />
          )}
        </div>
        <BookitButton
          variant='text'
          label={text.FOOTER.TERMS_AND_CONDITIONS_BUTTON}
          type='routerLink'
          routerPath='/terms-and-conditions'
          style={styles.mobileTermsAndConditionsButton}
        />
        {renderCreditMessage()}
      </div>
    );
  };

  return <BookitFooterAdvanced>{isMobile ? renderMobileView() : renderDesktopView()}</BookitFooterAdvanced>;
};

export default FooterAdvanced;
