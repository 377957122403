const styles: Record<string, React.CSSProperties> = {
  calendarWrapper: {
    width: '90%',
    overflow: 'auto',
  },

  headerItems: {
    textAlign: 'center',
    padding: '10px',
  },

  headerLeftSideTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '9px',
  },
};

export default styles;
