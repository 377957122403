import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import styles from './BookitButton.style';

interface IProps {
  variant: 'text' | 'contained' | 'outlined';
  label: string | React.JSX.Element;
  onClick?: (reservation: any) => void;
  disabled?: boolean;
  type: 'basic' | 'routerLink' | 'timePicker' | 'dayPicker';
  style?: React.CSSProperties;
  color?: 'primary' | 'secondary' | 'error';
  routerPath?: string;
  disableRipple?: boolean;
  loading?: boolean;
  children?: React.ReactNode;
}

const BookitButton = ({
  variant,
  label,
  onClick,
  disabled,
  type,
  style,
  color,
  routerPath,
  disableRipple,
  loading,
  children,
}: IProps) => {
  let routerLink;

  switch (type) {
    case 'basic':
      break;
    case 'routerLink':
      routerLink = RouterLink;
      break;
    case 'dayPicker':
      break;
    case 'timePicker':
      break;
    default:
      break;
  }

  const defaultTextStyles =
    (variant === 'text' && styles.textVariantButton) ||
    (variant === 'contained' && styles.containedVariantButton) ||
    (variant === 'outlined' && styles.outlinedVariantButton);

  const renderButtonContent = () => {
    if (loading) {
      return <CircularProgress color='inherit' size={24} />;
    }
    return (
      <>
        {label}
        {children}
      </>
    );
  };

  return (
    <Button
      color={color}
      sx={{ ...defaultTextStyles, ...style }}
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      component={routerLink}
      to={routerPath}
      disableRipple={disableRipple}
    >
      {renderButtonContent()}
    </Button>
  );
};

export default BookitButton;
