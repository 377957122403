import theme from '../../../themes/theme';

const container: React.CSSProperties = {
  backgroundColor: theme.palette.secondary.dark,
  display: 'flex',
  borderTop: `1px solid ${theme.palette.primary.main}`,
};

const styles: Record<string, React.CSSProperties> = {
  mainContainer: {
    ...container,
    padding: '2rem 0 0.5rem 0',
    overflow: 'hidden',
  },

  mainContainerMobile: {
    ...container,
    flexDirection: 'column-reverse',
    padding: '2rem 0 1.5rem 0',
    overflow: 'hidden',
  },
};

export default styles;
