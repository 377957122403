import { makeStyles } from '@mui/styles';
import theme from '../../../../../themes/theme';

const menuText = {
  color: theme.palette.primary.contrastText,
  fontFamily: theme.typography.fontFamily,
  cursor: 'pointer',
};

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
    height: '100vh',
    backgroundColor: theme.palette.secondary.main,
  },

  menuText: menuText,
  selectedMenuText: {
    ...menuText,
    color: theme.palette.primary.main,
  },

  contentWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flex: '1',
    margin: '0 10px 0 10px',
    overflow: 'hidden',
    height: '100%',
  },

  // headerMainWrapper: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   width: '100%',
  //   margin: '0 0 2rem 0',
  //   paddingBottom: '1rem',
  //   borderBottom: `1px solid ${theme.palette.primary.main}`,
  // },

  headerMainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    margin: '0 0 2rem 0',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },

  // headerBottomWrapper: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   justifyContent: 'space-between',
  //   padding: '0 1.5rem'
  // },

  titleText: {
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.h1.fontFamily,
    textTransform: 'uppercase',
    margin: '0.5rem 0 1.5rem 0',
    textAlign: 'center',
    fontSize: theme.typography.h1.fontSize,
  },

  welcomeBackText: {
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    margin: '0.2rem 0 0 0',
    fontSize: theme.typography.h5.fontSize,
    textAlign: 'center',
  },

  bottomButtonsWrapper: {
    display: 'flex',
    margin: '0 1.5rem 1rem 1.5rem',
    justifyContent: 'space-between',
  },
});
