import { configureStore } from '@reduxjs/toolkit';

import networkReducer from '../features/App/redux/appSlice';
import authReducer from '../features/Auth/redux/authSlice';
import authSocialReducer from '../features/Auth/redux/authSocialSlice';
import profileReducer from '../features/Home/redux/profileSlice';
import cancelReservationReducer from '../features/Home/redux/cancelReservatonSlice';
import companyServicesReducer from '../features/Home/redux/companyServicesSlice';
import companyResourcesSlotsReducer from '../features/Home/redux/companySlotsSlice';
import companySelectedResourcesReducer from '../features/Home/redux/companySelectedResourcesSlice';
import ongoingReservationReducer from '../features/Home/redux/ongoingReservationSlice';
import reservationsReducer from '../features/Home/redux/reservationsSlice';
import backOfficeAppointmentsReducer from '../features/BackOffice/BackOfficeHome/redux/boAppointmentsSlice';
import backOfficeUsersReducer from '../features/BackOffice/BackOfficeHome/redux/boUsersSlice';
import errorReducer from '../features/App/redux/errorSlice';
import navigationMiddleware from './navigationMiddleware';
import backOfficeAuthReducer from '../features/BackOffice/BackOfficeAuth/redux/boAuthSlice';
import backOfficeProfileReducer from '../features/BackOffice/BackOfficeHome/redux/boProfileSlice';

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const rootReducers = {
  ongoingReservation: ongoingReservationReducer,
  reservations: reservationsReducer,
  reservationForCancel: cancelReservationReducer,
  auth: authReducer,
  authSocial: authSocialReducer,
  profile: profileReducer,
  network: networkReducer,
  companyServices: companyServicesReducer,
  companyResourcesSlots: companyResourcesSlotsReducer,
  companySelectedResources: companySelectedResourcesReducer,
  backOfficeAppointments: backOfficeAppointmentsReducer,
  backOfficeUsers: backOfficeUsersReducer,
  backOfficeAuth: backOfficeAuthReducer,
  backOfficeProfile: backOfficeProfileReducer,
  error: errorReducer,
};

export const store = configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(navigationMiddleware),
});
