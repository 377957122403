import React, { useEffect, useState } from 'react';
import ScheduleAppointmentsCalendar from './components/ScheduleAppointmentsCalendar/ScheduleAppointmentsCalendar';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../../types';
import { AppDispatch } from '../../../../../store/store';
import { addBoAppointmentThunk, getBoScheduledAppointmentsThunk } from '../../redux/boAppointmentsSlice';
import { handleNewErrorThunk } from '../../../../App/redux/errorSlice';
import BackOfficeAddReservationDialog from '../../dialogs/BackOfficeAddReservationDialog/BackOfficeAddReservationDialog';
import BackOfficeViewEventDialog from '../../dialogs/BackOfficeViewEventDialog/BackOfficeViewEventDialog';
import BackOfficeRefresh from '../BackOfficeRefresh/BackOfficeRefresh';
import { useStyles } from './BackOfficeScheduleAppointmentsTable.style';

const BackOfficeScheduleAppointmentsTable = ({}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSlotData, setSelectedSlotData] = useState(null);
  const [isNewAppointmentDialogOpen, setIsNewAppointmentDialogOpen] = useState(false);
  const [isEditAppointmentDialogOpen, setIsEditAppointmentDialogOpen] = useState(false);
  const styles = useStyles();

  const { scheduledAppointments, loading } = useSelector((state: IStore) => state.backOfficeAppointments);
  const dispatch = useDispatch<AppDispatch>();

  const getBoScheduleAppointments = async () => {
    try {
      await dispatch(getBoScheduledAppointmentsThunk({ date: selectedDate.toDateString() })).unwrap();
    } catch (e) {
      dispatch(handleNewErrorThunk(e));
    }
  };

  useEffect(() => {
    getBoScheduleAppointments();
  }, [selectedDate]);

  const handleDateNavigate = (date: Date) => {
    setSelectedDate(date);
  };

  const handleSelectSlotClick = (slotData) => {
    setSelectedSlotData(slotData);
    setIsNewAppointmentDialogOpen(true);
  };

  const handleEventSlotClick = (eventData) => {
    setSelectedSlotData(eventData);
    setIsEditAppointmentDialogOpen(true);
  };

  const handleConfirm = async (data) => {
    try {
      await dispatch(addBoAppointmentThunk(data)).unwrap();
      getBoScheduleAppointments();
      setIsNewAppointmentDialogOpen(false);
    } catch (e) {
      dispatch(handleNewErrorThunk(e));
    }
  };

  const handleRefreshSchedule = async () => {
    await getBoScheduleAppointments();
  };

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.refreshWrapper}>
        <BackOfficeRefresh onRefresh={handleRefreshSchedule} />
      </div>
      {scheduledAppointments && (
        <ScheduleAppointmentsCalendar
          appointments={scheduledAppointments}
          onDateChange={handleDateNavigate}
          onSelectSlotClick={handleSelectSlotClick}
          onEventSlotClick={handleEventSlotClick}
        />
      )}
      {isNewAppointmentDialogOpen && (
        <BackOfficeAddReservationDialog
          open={isNewAppointmentDialogOpen}
          selectedSlotData={selectedSlotData}
          onClose={() => {
            setIsNewAppointmentDialogOpen(false);
          }}
          onConfirm={handleConfirm}
          loading={loading}
        />
      )}
      {isEditAppointmentDialogOpen && (
        <BackOfficeViewEventDialog
          open={isEditAppointmentDialogOpen}
          selectedSlotData={selectedSlotData}
          selectedDate={selectedDate}
          onClose={() => {
            setIsEditAppointmentDialogOpen(false);
          }}
          onConfirm={handleConfirm}
          loading={loading}
        />
      )}
    </div>
  );
};
export default BackOfficeScheduleAppointmentsTable;
