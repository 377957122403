import theme from '../../themes/theme';

const styles: Record<string, React.CSSProperties> = {
  drawer: {
    padding: '1.5rem 0 0 0',
  },

  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.main,
    height: '100%',
  },

  drawerHeader: {
    padding: '0 1.5rem',
  },

  mobileDrawerHeader: {
    padding: '0 0.5rem',
  },

  drawerData: {
    overflow: 'auto',
    flex: 1,
    padding: '0 1.5rem 0 1.5rem',
  },

  mobileDrawerData: {
    overflow: 'auto',
    flex: 1,
    padding: '0 0.5rem 0 0.5rem',
  },

  loginAlertWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 0 1rem 0',
  },

  loginButton: {
    width: '20rem',
  },

  image: {
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    aspectRatio: '2.5 / 1',
  },

  infoContainer: {
    textAlign: 'left',
  },

  mobileInfoContainer: {
    textAlign: 'left',
  },

  drawerInfo: {
    margin: '0 0 1.8rem 0',
    color: theme.palette.primary.contrastText,
    display: 'flex',
    justifyContent: 'space-between',
  },

  label: {
    color: theme.palette.secondary.light,
  },

  noteWrapper: {
    margin: '0 0 1.5rem 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    color: theme.palette.primary.contrastText,
  },

  noteTitle: {
    padding: '0 0 0.5rem 0.5rem',
    margin: 0,
    fontSize: '0.9rem',
  },

  note: {
    width: '100%',
    // @ts-ignore
    '& .MuiOutlinedInput-root': {
      color: theme.palette.primary.contrastText,
      '& fieldset': {
        borderColor: theme.palette.secondary.light,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.secondary.light,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary.light,
        borderWidth: '1px',
      },
      '& textarea': {
        minHeight: '4.5rem',
        maxHeight: '4.5rem',
      },
      '& input': {
        color: theme.palette.primary.contrastText,
      },
    },
    '& .MuiInputBase-input::placeholder': {
      color: theme.palette.secondary.light,
    },
  },

  divider: {
    borderColor: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.light,
  },

  price: {
    margin: '2rem 0 2.5rem 0',
    color: theme.palette.primary.contrastText,
    display: 'flex',
    justifyContent: 'space-between',
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5rem 4rem 3.5rem 3rem',
  },

  mobileButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1rem 0 0rem 0',
  },

  submitButton: {
    height: '2.8rem',
    width: '100%',
    marginLeft: '0.5rem',
    borderRadius: '0.1rem',
    boxShadow: 'none',
    fontWeight: theme.typography.h1.fontWeight,
    letterSpacing: '0.05rem',
    // @ts-ignore
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      transform: 'scale(0.99)',
      boxShadow: 'none',
    },
  },

  discardButton: {
    height: '2.8rem',
    width: '100%',
    marginRight: '0.5rem',
    borderRadius: '0.1rem',
    color: 'white',
    fontWeight: theme.typography.h1.fontWeight,
    letterSpacing: '0.05rem',
    textTransform: 'uppercase',
    // @ts-ignore
    '&:hover': {
      color: theme.palette.secondary.light,
    },
    '&:active': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.light,
      transform: 'scale(0.98)',
    },
  },

  mobileSubmitButton: {
    height: '3rem',
    width: '100%',
    marginBottom: '1.5rem',
    borderRadius: '0.1rem',
    boxShadow: 'none',
    fontWeight: theme.typography.h1.fontWeight,
    letterSpacing: '0.05rem',
    // @ts-ignore
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      transform: 'scale(0.99)',
      boxShadow: 'none',
    },
  },

  mobileDiscardButton: {
    height: '3rem',
    width: '100%',
    borderRadius: '0.1rem',
    color: 'white',
    fontWeight: theme.typography.h1.fontWeight,
    letterSpacing: '0.05rem',
    textTransform: 'uppercase',
    // @ts-ignore
    '&:hover': {
      color: theme.palette.secondary.light,
    },
    '&:active': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.light,
      transform: 'scale(0.98)',
    },
  },
};

export default styles;
