import React, { useEffect, useState } from 'react';
import BookitDialog from '../../../../../components/foundation/BookitDialog/BookitDialog';
import { TextField } from '@mui/material';
import { text } from '../../../../../constants/constants';
import styles from './BackOfficeAddReservationDialog.style';
import BookitDropdown from '../../../../../components/foundation/BookitDropdownField/BookitDropdownField';

interface IProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (reservation: any) => void;
  loading: boolean;
  selectedSlotData?: any;
}

const localeDateString = 'sr-RS';

const BackOfficeAddReservationDialog = ({ open, onClose, onConfirm, selectedSlotData, loading }: IProps) => {
  const [note, setNote] = useState(null);
  const [date, setDate] = useState(new Date().toDateString());
  const [time, setTime] = useState(new Date().toTimeString());
  const [service, setService] = useState(null);

  const data = {
    note: note,
    appointments: [
      {
        service_id: service?.id,
        resource_id: selectedSlotData.resourceId,
        start_date: selectedSlotData.start,
        end_date: selectedSlotData.end,
        note: note,
      },
    ],
  };

  const handleConfirm = () => {
    onConfirm(data);
    setService(null);
  };

  useEffect(() => {
    if (selectedSlotData?.services?.length === 1) {
      setService(selectedSlotData.services[0]);
    }
  }, []);

  useEffect(() => {
    setDate(new Date(selectedSlotData?.start).toLocaleDateString(localeDateString));
    setTime(new Date(selectedSlotData?.start).toLocaleTimeString(localeDateString));
  }, [selectedSlotData]);

  const renderNote = () => (
    <div style={styles.noteWrapper}>
      <p style={styles.noteTitle}>{text.BO_ADD_RESERVATION_DIALOG.NOTE_TITLE}</p>
      <TextField
        multiline
        placeholder={text.BO_ADD_RESERVATION_DIALOG.NOTE_PLACEHOLDER}
        value={note}
        onChange={(e) => setNote(e.target.value)}
        maxRows={4}
        sx={styles.note}
      />
    </div>
  );

  return (
    <BookitDialog
      buttonContainerStyle={styles.buttonContainer}
      buttonStyle={styles.button}
      open={open}
      onClose={onClose}
      onConfirm={() => handleConfirm()}
      titleLabel={text.DIALOG_TEXT.ADD_NEW_RESERVATION}
      titleStyle={styles.title}
      discardButtonColor='primary'
      confirmButtonColor='primary'
      discardLabel={text.DIALOG_TEXT.DEFAULT_DISCARD}
      confirmLabel={text.DIALOG_TEXT.DEFAULT_CONFIRM}
      confirmButtonDisabled={!note || !service}
      loading={loading}
    >
      <div style={styles.dialogContainer}>
        <div style={styles.filedWrapper}>
          <p>{text.BO_ADD_RESERVATION_DIALOG.DATE_TITLE}</p>
          <p>{date}</p>
        </div>
        <div style={styles.filedWrapper}>
          <p>{text.BO_ADD_RESERVATION_DIALOG.TIME_TITLE}</p>
          <p>{time}</p>
        </div>
        <div style={styles.filedWrapper}>
          <p>{text.BO_ADD_RESERVATION_DIALOG.FIELD_TITLE}</p>
          <p>{selectedSlotData.resourceName}</p>
        </div>
        <div style={styles.filedWrapper}>
          <p>{text.BO_ADD_RESERVATION_DIALOG.SPORT_TITLE}</p>

          <BookitDropdown
            options={selectedSlotData.services}
            value={service}
            getOptionLabel={(option: { id: string; name: string }) => option.name}
            placeholder={(params) => <TextField {...params} label={text.BO_ADD_RESERVATION_DIALOG.DROPDOWN_PLACEHOLDER} />}
            onChange={(event, newValue) => {
              setService(newValue);
            }}
            disableClearable
            autoHighlight
            style={styles.sportDropdown}
          />
        </div>
        {renderNote()}
      </div>
    </BookitDialog>
  );
};

export default BackOfficeAddReservationDialog;
