export const text = {
  DATA_ENTRY_FORM: 'Unesite podatke',
  CHOSEN_FIELD: 'Izabrani termin',
  NAME: 'Ime i prezime',
  SUBMIT_BUTTON: 'Rezerviši',
  PHONE_NUMBER: 'Broj telefona',
  CALENDAR_TEXT: 'Izaberite datum',
  FIELD_CARD_NO_SLOTS: 'Nema slobodnih termina',
  EMAIL_HELPER_TEXT: 'Molimo unesite validnu e-mail adresu',
  BO_BUTTON: 'Go to BO',
  BO_NO_DATA: 'Nema podataka',
  BO_NO_SEARCH_DATA: 'Nema podataka za zadati kriterijum pretrage.',
  GOOGLE_AUTH_ERROR_MESSAGE: 'Autentifikacija neuspešna. Pokušajte ponovo.',
  LOGIN: {
    TITLE: 'Uloguj se',
    WELCOME_TEXT: 'Dobrodošli. Ulogujte se kako biste zakazali Vaš termin u SunRise sportskom centru.',
    LOGIN_FIRST: 'Da biste rezervisali termin, neophodno je da se ulogujete.',
    EMAIL: 'E-mail',
    PASSWORD: 'Lozinka',
    LOGIN: 'Uloguj se',
    FORGOT_PASS: 'Zaboravili ste lozinku?',
    NO_ACCOUNT: 'Nemate nalog?',
    DIVIDER_TEXT: 'Ili',
  },
  SIGNUP: {
    NO_ACCOUNT_BUTTON: 'Registrujte se',
    WELCOME_TEXT: 'Registrujte se kako biste brzo i lako zakazali termin u SunRise sportskom centru.',
    NAME: 'Ime',
    SURNAME: 'Prezime',
    EMAIL: 'E-mail',
    PASSWORD: 'Lozinka',
    SIGNUP: 'Registruj se',
    LOGIN_BUTTON: 'Ulogujte se',
    PHONE_NUMBER: 'Broj telefona',
    PHONE_CODE_INPUT_LABEL: 'Pozivni broj',
    HAVE_ACCOUNT: 'Imate nalog?',
    DIVIDER_TEXT: 'Ili',
    ENTER_PHONE_NUMBER_TEXT: 'Molimo unesite broj telefona da biste završili registraciju',
    TERMS_AND_CONDITIONS_TEXT: 'Ako kliknete na dugme, prihvatate naše',
    TERMS_AND_CONDITIONS_LINK: 'Uslove korišćenja i politiku privatnosti.',
  },
  FORGOT_PASSWORD: {
    TITLE: 'Zaboravili ste lozinku?',
    DESCTRIPTION: 'Unesite email, kako bismo Vam poslali email za promenu lozinke.',
    EMAIL: 'E-mail',
    CONFIRM_BUTTON: 'Pošalji',
    HAVE_ACCOUNT: 'Imate nalog?',
    LOGIN_BUTTON: 'Ulogujte se',
    RESET_PASSWORD_MESSAGE: 'Proverite email i resetujte lozinku preko linka.',
  },
  DIALOG_TEXT: {
    CONFIRM_CANCEL_DIALOG_TITLE: 'Otkažite termin',
    DEFAULT_CONFIRM: 'Potvrdi',
    DEFAULT_DISCARD: 'Odustani',
    SELECTED_FIELD: 'Teren',
    SELECTED_FIELD_DETAILS: 'Detalji',
    SELECTED_TIME: 'Vreme',
    NOTE_TITLE: 'Napomena',
    ADD_NEW_RESERVATION: 'Kreiranje nove rezervacije',
    BLOCK_USER_DIALOG_BLOCK_TITLE: 'Blokiraj korisnika',
    BLOCK_USER_DIALOG_BLOCK_TEXT: 'Da li ste sigurni da želite da blokirate',
    BLOCK_USER_DIALOG_UNBLOCK_TITLE: 'Odblokiraj korisnika',
    BLOCK_USER_DIALOG_UNBLOCK_TEXT: 'Da li ste sigurni da želite da odblokirate',
  },
  CONFIRM_RESERVATION_DRAWER: {
    TITLE: 'Rezervacija termina',
    SELECTED_FIELD: 'Teren',
    SELECTED_FIELD_DETAILS: 'Detalji',
    SELECTED_DATE: 'Datum',
    SELECTED_TIME: 'Vreme',
    PRICE: 'Cena termina',
    NOTE_TITLE: 'Napomena (opciono)',
    NOTE_PLACEHOLDER: 'Napomena',
    SUBMIT_BUTTON: 'Potvrdi',
    DISCARD_BUTTON: 'Odustani',
  },
  PASSWORD_CONDITIONS_TEXT: {
    MIN_LENGTH: 'Najmanje 8 karaktera',
    UPPER_LOWER: 'Kombinacija malih i velikih slova',
    NUMBER: 'Najmanje jedan broj',
    SPECIAL_CHAR: 'Najmanje jedan specijalni karakter',
  },
  FOOTER: {
    FOLLOW_US_TEXT: 'Pratite nas na društvenim mrežama',
    ABOUT_US: 'O nama',
    LEAGUES: 'Lige',
    FOOTBALL_SCHOOL: 'Škola fudbala',
    TENNIS_SCHOOL: 'Škola tenisa',
    RECREATIONAL_SPORTS: 'Rekreativni sport',
    CONTACT: 'Kontakt',

    RESERVATION_TITLE: 'Besplatno zakažite termin',
    RESERVATION_DESCRIPTION: 'Zakažite svoj termin brzo, jednostavno i uživajte sa prijateljima.',
    RESERVATION_BUTTON: 'Rezervišite termin',
    TERMS_AND_CONDITIONS_BUTTON: 'Uslovi korišćenja i politika privatnosti',
  },
  LOGO: {
    TITLE: 'SunRise',
    TITLE_TEXT: 'sportski centar',
  },
  HEADER: {
    ACTIVE_RESERVATIONS: 'Aktivne rezervacije',
    PROFILE: 'MOJ NALOG',
    NO_ACTIVE_RESERVATIONS_DRAWER_TITLE: 'Trenutno nemate aktivnih rezervacija',
    INFO_FIELDS_TITLE: 'Lični podaci',
    FIRST_NAME_INPUT_FIELD: 'Ime',
    LAST_NAME_INPUT_FIELD: 'Prezime',
    PHONE_INPUT_FIELD: 'Telefon',
    CONFIRM_DATA_CHANGES_BUTTON: 'Sačuvaj',
    CHANGE_PASSWORD_BUTTON: 'Promenite lozinku',
    LOG_OUT_BUTTON: 'Izlogujte se',
    RESEND_CONFIRM_BUTTON: 'Pošalji ponovo',
    CONFIRM_ACC_TITLE: 'Molimo Vas da potvrdite nalog koji je poslat na  ',
    CONFIRM_ACC_TEXT:
      ' Ukoliko niste dobili email za potvrdu naloga, ili je isti istekao, pošaljite ga ponovo klikom na dugme ispod.',
    RESET_PASSWORD_MESSAGE: 'Proverite email i resetujte lozinku preko linka.',
    SUCCESS_CANCEL_RESERVATION_MESSAGE: 'Uspešno ste otkazali termin.',
    LOGOUT_MESSAGE: 'Uspešno ste se izlogovali.',
  },

  HOME: {
    SUCCESS_RESERVATION_MESSAGE: 'Uspešno ste rezervisali termin. Vidimo se.',
  },

  ABOUT_US: {
    TITLE: 'About Us',
    MARKER_POPUP: 'SC SunRise',
    FACEBOOK_TITLE: 'Facebook',
    INSTAGRAM_TITLE: 'Instagram',
  },

  WEEK_DAYS: {
    MONDAY: 'Pon',
    TUESDAY: 'Uto',
    WEDNESDAY: 'Sre',
    THURSDAY: 'Čet',
    FRIDAY: 'Pet',
    SATURDAY: 'Sub',
    SUNDAY: 'Ned',
  },
  BO_HOME: {
    TOOLTIP: 'Obriši rezervaciju',
    BLOCK_USER_TOOLTIP: 'Blokiraj korisnika',
    UNBLOCK_USER_TOOLTIP: 'Aktiviraj korisnika',
  },
  BO_DROPDOWNS: {
    RESOURCE: 'Teren',
    SERVICE: 'Sport',
    SLOT: 'Termin',
  },
  BO_CALENDAR: {
    HEADER_TITLE: 'Vreme',
  },
  BO_ADD_RESERVATION_DIALOG: {
    DATE_TITLE: 'Datum:',
    TIME_TITLE: 'Vreme:',
    FIELD_TITLE: 'Teren:',
    SPORT_TITLE: 'Sport:',
    NOTE_TITLE: 'Beleška:',
    NOTE_PLACEHOLDER: 'Unesite belešku',
    DROPDOWN_PLACEHOLDER: 'Izaberi sport',
  },
  BO_VIEW_EVENT_DIALOG: {
    TITLE: 'Pregled rezervacije',
    NAME_TITLE: 'Ime i prezime:',
    PHONE_TITLE: 'Broj telefona:',
    DATE_TITLE: 'Datum:',
    TIME_TITLE: 'Vreme:',
    FIELD_TITLE: 'Teren:',
    NOTE_TITLE: 'Beleška:',
    NOTE_PLACEHOLDER: 'Unesite belešku',
    CANCEL_RESERVATION_BUTTON: 'Otkaži rezervaciju?',
  },
  BO_MENU_DRAWER: {
    TITLE: 'Admin panel',
  },
  BO_REFRESH: {
    TITLE: 'Podaci učitani: ',
    TOOLTIP: 'Osveži podatke',
  },
  BO_USERS: {
    ALL_USERS: 'Svi',
    ACTIVE_USERS: 'Aktivni',
    BLOCKED_USERS: 'Blokirani',
    AWAITING_VERIFICATION_USERS: 'Čekaju verifikaciju',
    REGISTRATION_DATE: 'Datum registracije',
    NAME: 'Ime i prezime',
    PHONE: 'Telefon',
    EMAIL: 'Email',
    STATUS: 'Status',
  },

  SERVICE_PAGES: {
    UNDER_CONSTRUCTION_TITLE: 'Stranica je u izradi',
    UNDER_CONSTRUCTION_SUBTITLE: 'Vidimo se uskoro!',
    NOT_FOUND_TITLE: '404',
    NOT_FOUND_SUBTITLE: 'Stranica nije pronađena',
  },
};

export const links = {
  FACEBOOK_LINK: 'https://www.facebook.com/teniskiklub.sunrise/',
  INSTAGRAM_LINK: 'https://www.instagram.com/sc_sunrise_sabac/',
};

export const clientInfo = {
  LOCATION: {
    LATITUDE: 44.7563,
    LONGITUDE: 19.6747,
  },
};
