import React, { useState } from 'react';
import SunriseLogo from '../SunriseLogo/SunriseLogo';
import BookitButton from '../../../components/foundation/BookitButton/BookitButton';
import { text } from '../../constants';
import styles from './SunriseHeader.style';
import SunriseNavigationButtons from '../SunriseNavigationButtons/SunriseNavigationButtons';
import BookitIcon from '../../../components/foundation/BookitIcon/BookitIcon';
import BookitDrawer from '../../../components/foundation/BookitDrawer/BookitDrawer';
import { useMediaQuery } from '@mui/material';
import { useIsMobile } from '../../../hooks/IsMobileHook';
import featureFlags from '../../../featureFlags';

interface IProps {
  isTransparent?: boolean;
  isWhiteLogo?: boolean;
  logoSize?: 'small' | 'medium' | 'large';
}

const SunriseHeader = ({ isTransparent = false, isWhiteLogo = false, logoSize = 'large' }: IProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isMobile = useIsMobile();

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const isSmallScreen = useMediaQuery('(max-width:1000px)');

  const adjustedLogoSize = isMobile ? 'medium' : logoSize;

  return (
    <>
      <div style={isTransparent ? styles.welcomeHeaderTransparent : styles.welcomeHeader}>
        <SunriseLogo size={adjustedLogoSize} isLogoWhite={isWhiteLogo} />
        <div style={isSmallScreen ? styles.rightSideButtonsWrapperSmallScreen : styles.rightSideButtonsWrapper}>
          {isSmallScreen ? (
            <BookitIcon type='menu' onClick={handleDrawerToggle} style={styles.menuIcon} />
          ) : (
            <SunriseNavigationButtons isRowLayout={true} />
          )}
          {featureFlags.showReservationsButton && (
            <BookitButton
              variant='contained'
              label={text.RESERVATION_BUTTON}
              type='routerLink'
              routerPath='/reservations'
              style={styles.reservationButton}
              disableRipple
            />
          )}
        </div>
      </div>

      <BookitDrawer
        isOpen={isDrawerOpen}
        onClose={handleDrawerToggle}
        style={!isMobile && styles.drawer}
        childrenStyle={styles.drawerChildrenWrapper}
      >
        <SunriseNavigationButtons
          style={styles.navigationButtonsWrapper}
          buttonStyle={styles.navigationButtons}
          handleCloseDrawer={() => setIsDrawerOpen(false)}
        />
      </BookitDrawer>
    </>
  );
};

export default SunriseHeader;
