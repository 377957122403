export const text = {
  RESERVATION_BUTTON: 'Rezervišite termin',
  SEE_MORE: 'Vidi više...',
  WELCOME: {
    TITLE: 'Dobrodošli u Sportski centar Sunrise',
    SUBTITLE: 'Mesto gde se sport i strast susreću.',
  },
  ABOUT_US: {
    TITLE: 'O nama',
    SHORT_DSC:
      'SC Sunrise je moderan sportski centar koji se prostire na 4.8 ha između šume Zabran i Šabačkog hipodroma. Poseduje terene za fudbal, tenis, restoran, kafić i prostor za proslave, pružajući vrhunske uslove za sport, rekreaciju i organizaciju događaja.',
    LONG_DESC_01:
      'Sportski centar Sunrise predstavlja moderan kompleks koji prati svetske trendove i izdvaja se po kvalitetu usluga i uslova. Osnovan 2008. godine, nalazi se na atraktivnoj lokaciji između šume Zabran i Šabačkog hipodroma, na samo 5 minuta vožnje od centra Šapca. Prostire se na 4,8 hektara, što ga svrstava među veće sportske centre u zemlji.',
    LIST_01_TITLE: 'Trenutno je iskorišćeno 60% površine, na kojoj se nalaze:',
    LIST_01: [
      'Veliki fudbalski teren sa veštačkom travom (108x70m)',
      'Srednji fudbalski teren sa veštačkom travom (55x32m)',
      'Dva manja fudbalska terena (40x20m) na otvorenom, koji se tokom zime pokrivaju balon halama',
      'Balon hale:',
    ],
    LIST_02: [
      'Balon 1: Veštačka trava za fudbal (40x20m)',
      'Balon 2: Veštačka trava za fudbal (40x20m)',
      'Balon 3: Veštačka trava za fudbal (40x20m)',
    ],
    LIST_03: [
      'Decoturf sportska podloga (36x18m) za tenis, košarku i odbojku',
      'Četiri teniska terena sa šljakom',
      'Glavna zgrada: kafić, svlačionice, kancelarije i rođendaonica',
      'Restoran kod teniskih terena sa baštom i pogledom na terene',
      'Orange Club: prostor za proslave i rođendane do 150 gostiju, sa letnjom baštom i uživo muzikom',
      'Perionica za automobile',
    ],
    IN_FUTURE: 'U planu je iskorišćenje preostalog dela placa za izgradnju restorana, mini aqua parka i smeštajnih kapaciteta.',
    FINISH_LINE:
      'SC Sunrise je otvoren za sve vrste sportskih i ugostiteljskih aktivnosti, kao i za organizaciju tim bildinga i drugih događaja.',
  },
  LEAGUES: {
    TITLE: 'Lige i turniri',
    LEAGUE_01_TITLE: 'Mini-Maxi liga',
    LEAGUE_01_DSC:
      'Minimaxi liga se uspešno organizuje već 14 sezona zaredom. Ova prestižna liga okuplja ekipe ne samo iz Šabačke opštine već i iz gradova kao što su Valjevo, Loznica, Bijeljina, Sremska Mitrovica, Ub, Bogatić, Vladimirci, Koceljeva i drugih manjih mesta. Sa preko 120 ekipa svake godine, Minimaxi liga je najveća po brojnosti u našoj zemlji. Pored Minimaxi lige, dva puta godišnje organizujemo fudbalske turnire za decu, tokom zimskog i letnjeg perioda, čime podstičemo sportski duh i takmičarski entuzijazam među mlađim generacijama.',
    LEAGUE_01_SHORT_DSC:
      'Najveća liga u Srbiji, sa preko 120 ekipa iz Šapca, Valjeva, Loznice, Bijeljine i okolnih mesta. Već 14 sezona okuplja najmlađe fudbalere, pružajući im priliku za razvoj i takmičenje na vrhunskom nivou.',
    LEAGUE_02_TITLE: 'Biznis liga',
    LEAGUE_02_DSC:
      'Biznis liga Šabac, koja okuplja firme i preduzeća iz grada Šapca, održava se tokom zimskih meseci i predstavlja tradiciju našeg centra već 14 sezona.',
    LEAGUE_02_SHORT_DSC:
      'Tradicionalna zimska liga za kompanije i preduzeća iz Šapca, koja traje 14 sezona. Popularan izbor za sportsko druženje i nadmetanje u profesionalnom okruženju.',
    LEAGUE_03_TITLE: 'Turniri',
    LEAGUE_03_DSC:
      'Takođe, tokom godine organizujemo brojne fudbalske turnire za seniore, uključujući i turnire u mini fudbalu, koji privlače učesnike iz različitih krajeva. Naš centar je prepoznatljiv po posvećenosti sportu, kvalitetnoj organizaciji i stvaranju prostora za sve uzraste i nivoe takmičenja.',
    LEAGUE_03_SHORT_DSC:
      'Tokom zime i leta organizujemo turnire za decu svih uzrasta, dok seniori učestvuju u mini fudbalskim turnirima. Naši događaji okupljaju igrače iz celog regiona i promovišu sport i zajedništvo.',
  },
  FOOTBALL_SCHOOL: {
    TITLE: 'Škola fudbala i fudbalski klub',
    SC_TITLE: 'Škola fudbala Sunrise',
    SC_SHORT_DSC:
      'Škola fudbala Sunrise broji preko 300 polaznika u kategorijama od najmlađih do omladinaca, sa licenciranim trenerima koji svakodnevno održavaju treninge na modernim otvorenim i zatvorenim terenima. Osim fudbalskih veština, deca razvijaju timski duh i disciplinu. Fudbalski klub Sunrise ima prvi tim i omladinske selekcije koje se takmiče na lokalnim, regionalnim i saveznim nivoima, sa ambicijom da nastave napredovanje i ostvare zapažene rezultate u budućnosti.',
    SC_DSC_01:
      'Naša škola fudbala trenutno broji preko 300 polaznika, organizovanih u kategorijama U19, U18, U17, U16, U15, U14 i U13. Treninzi se održavaju svakodnevno na otvorenim i zatvorenim terenima sa veštačkom travom. Svaku generaciju vode po dva licencirana trenera sa relevantnim sertifikatima, a u okviru škole organizovani su i specijalizovani treninzi za golmane. Treneri su:',
    SC_DSC_01_LIST: [
      'Jane Gavalovski',
      'Ivan Radovanović',
      'Nenad Jovanović',
      'Nemanja Simić',
      'Strahinja Petrović',
      'Ivan Ubavić',
      'Saša Kolarić (trener golmana)',
    ],
    SC_DSC_02:
      'Škola fudbala je otvorena za nove članove tokom cele godine. Svakog proleća organizujemo promotivne pokazne treninge, na kojima deca i roditelji mogu upoznati naš rad, razgovarati sa trenerima i probati treninge.',
    FC_TITLE: 'Fudbalski klub Sunrise',
    FC_DSC_01: 'FK Sunrise okuplja veliki broj igrača kroz prvi tim i sve omladinske selekcije:',
    FC_DSC_01_LIST: ['Omladinci', 'Kadeti (dve ekipe)', 'Pioniri (dve ekipe)', 'Petlići (dve ekipe)'],
    FC_DSC_02:
      'Prvi tim se takmiči u Međuopštinskoj ligi, grupa Mačva, sa oko 30 fudbalera, od kojih većina dolazi iz naše omladinske škole. Ambicija kluba je konstantan napredak, s ciljem da u narednim sezonama izborimo učešće u saveznoj ligi.',
    FC_DSC_02_01: 'Takmičenja omladinskih selekcija:',
    FC_DSC_02_LIST: [
      'Omladinci – Zapadna Srbija',
      'Kadeti 1 – I liga Zapadne Srbije',
      'Kadeti 2 – I liga Mačvansko-kolubarskog regiona',
      'Pioniri 1 – I liga Zapadne Srbije',
      'Pioniri 2 – I liga Mačvanskog okruga',
      'Petlići 1 – I liga Mačvansko-kolubarskog okruga',
      'Petlići 2 – Opštinska liga Šabac',
    ],
    FC_DSC_03:
      'Treninzi za sve selekcije održavaju se svakodnevno na terenima sa veštačkom travom, a tokom loših vremenskih uslova treninzi se premeštaju u balon hale.',
    FC_DSC_03_LIST_TITLE: 'Treneri omladinskih selekcija:',
    FC_DSC_03_LIST: [
      'Jane Gavalovski',
      'Dragan Ljuština',
      'Ivan Radovanović',
      'Strahinja Petrović',
      'Saša Kolarić (trener golmana)',
    ],
    FC_DSC_04_TITLE: 'Naše ambicije',
    FC_DSC_04:
      'Naš cilj je uvek najviši plasman u svim kategorijama i generacijama. FK Sunrise ima sjajnu saradnju sa najvećim klubovima u zemlji, a mnogi naši igrači danas igraju u vodećim domaćim i inostranim timovima, čime potvrđujemo kvalitet našeg rada i posvećenosti razvoju mladih talenata.',
  },
  TENNIS_SCHOOL: {
    TITLE: 'Škola tenisa i teniski klub',
    SHORT_DSC:
      'Škola tenisa Sunrise nudi profesionalne treninge za decu svih uzrasta i nivoa, sa licenciranim trenerima i uslovima na 4 šljakasta terena i jednim pokrivenim balon halom. Treninge prilagođavamo potrebama svakog polaznika, pomažući im da razviju tehniku i ljubav prema tenisu. Teniski klub organizuje rekreativne termine i domaće i međunarodne turnire, a pored škole tenisa, aktivno se bavi i razvojem takmičarskih igrača, pružajući im priliku da nastupe na najvećim takmičenjima.',
    SC_TITLE: 'Škola tenisa Sunrise',
    SC_DSC_01:
      'Škola tenisa je pionirski projekat SC Sunrise, pokrenut još 2008. godine. Na raspolaganju imamo 4 terena sa šljakom i 1 teren pod balon halom tokom zimskih uslova. Od prošle godine, jedan teren sa šljakom zamenjen je popularnom Decoturf sportskom podlogom (kakva se koristi na US Open turniru). Ovaj teren, pokriven modernom balon halom, omogućava igru tokom cele godine, bez obzira na vremenske uslove',
    SC_DSC_02:
      'Treninzi u Školi tenisa se održavaju svakodnevno, tokom cele godine, na otvorenim i zatvorenim terenima. Trenutno imamo oko 50 polaznika, podeljenih prema uzrastu i nivou znanja.',
    SC_LIST_TITLE: 'Treneri Škole tenisa:',
    SC_LIST: ['Nenad Crnogorac', 'Goran Mandić', 'Nemanja Ilić', 'Nina Gajić'],
    SC_DSC_03: 'Svi naši treneri poseduju odgovarajuće licence i višegodišnje iskustvo u radu sa decom i mladim teniserima.',
    TC_TITLE: 'Teniski klub Sunrise',
    TC_DSC_01:
      'Pored Škole tenisa, Teniski klub Sunrise pruža usluge i za takmičare i za rekreativce. Klub nudi mogućnost zakazivanja termina za rekreativnu igru svakog dana od 08:00 do 23:00, na otvorenim i zatvorenim terenima.',
    TC_DSC_02:
      'Budući i aktuelni takmičari imaju priliku da se direktno dogovore sa trenerima o individualnom planu rada i angažmanu. Klub takođe organizuje veliki broj domaćih i međunarodnih turnira za sve uzrasne kategorije i ima dugogodišnju tradiciju u promociji i razvoju novih teniskih talenata.',
    TC_DSC_03:
      'Naš cilj je da nastavimo da pružamo vrhunske uslove za treniranje i takmičenje, kao i da postanemo prepoznatljiv centar za razvoj mladih tenisera i promociju tenisa u regionu.',
  },
  RECREATIONAL_SPORT: {
    TITLE: 'Rekreativni sport',
    SHORT_DSC:
      'SC Sunrise nudi 12 terena za rekreaciju i treninge, koji su dostupni kako sportskim klubovima i takmičarskim selekcijama, tako i rekreativcima. \nTerene je moguće iznajmiti za različite sportske aktivnosti, dok su naši prostori idealni za organizaciju team building događaja za firme. Pružamo vrhunske uslove za sve vrste sportskih aktivnosti, od fudbala do drugih rekreativnih disciplina, uz savremenu infrastrukturu i opremu.',
    DSC_01:
      'SC Sunrise raspolaže sa 12 igrališta, koja su dostupna kako za potrebe naših takmičarskih selekcija, tako i za iznajmljivanje sportskim klubovima, ekipama i rekreativcima. Svi tereni u našem kompleksu mogu se iznajmiti po dogovoru, pružajući odlične uslove za različite sportske aktivnosti.',
    DSC_02:
      'Osim individualne rekreacije, organizujemo i team building aktivnosti za kompanije koje žele da svojim zaposlenima pruže jedinstven vid druženja i sportskog angažovanja. Naša raznovrsna ponuda sportskih sadržaja garantuje kvalitetno provedeno vreme u opuštenoj i profesionalnoj atmosferi.',
    DSC_03:
      'Bez obzira da li ste rekreativac, član sportskog kluba ili organizator korporativnog događaja, SC Sunrise je pravo mesto za vas!',
    PRICE_INFO: 'Informacije o cenama možete videti',
    LINK_TEXT: 'ovde.',
  },
  PHONE_NUMBER: '+381 62 308 607',
  RECREATIONAL_SPORTS_TITLE: 'Rekreativni sport',
  PRICING_BUTTON: 'Pogledajte cenovnik',
  CONTACT_DETAILS_TITLE: 'Gde se nalazimo',
  MAP_MARKER_POPUP: 'SC Sunrise',

  CONTACT_CARD: {
    ADDRESS_TITLE: 'Adresa',
    PHONE_TITLE: 'Telefon',
    EMAIL_TITLE: 'Email',
    ADDRESS_SUBTITLE: 'Banjičkih žrtava bb, Šabac',
    PHONE_SUBTITLE: '+381 62 308 607',
    EMAIL_SUBTITLE: 'office@scsunrise.rs',
  },
  TERMS_AND_CONDITIONS: {
    TITLE: 'Uslovi korišćenja i politika privatnosti',
    EFFECTIVE_DATE: '01.01.2025',
    INTRODUCTION: {
      TITLE: '1. Informacije',
      DESCRIPTION:
        'Ova politika privatnosti opisuje kako prikupljamo, koristimo i delimo informacije kada se prijavite na našu aplikaciju.',
    },
    DATA_COLLECT: {
      TITLE: '2. Informacije koje prikupljamo',
      DESCRIPTION: 'Kada se prijavite na našu aplikaciju, možemo prikupljati sledeće informacije:',
      DESCRIPTION_LIST: ['Ime i prezime', 'Email adresa', 'Profilna slika'],
      DESCRIPTION_2: 'Ove informacije će nam biti dostupne kada odobrite pristup.',
    },
    DATA_USAGE: {
      TITLE: '3. Kako koristimo vaše informacije',
      DESCRIPTION: 'Prikupljene informacije koristimo za:',
      DESCRIPTION_LIST: [
        'Kreiranje i upravljanje vašim nalogom',
        'Omogućavanje pristupa funkcijama našeg proizvoda',
        'Komunikaciju sa vama, uključujući obaveštenja o ažuriranjima ili promotivne informacije',
      ],
    },
    DATA_SHARING: {
      TITLE: '4. Deljenje informacija',
      DESCRIPTION: 'Vaše lične informacije nećemo deliti sa trećim stranama, osim kada:',
      DESCRIPTION_LIST: ['Imamo vaše odobrenje', 'To zahteva zakonodavstvo ili pravni proces'],
    },
    SECURITY: {
      TITLE: '5. Bezbednost',
      DESCRIPTION:
        'Preduzimamo odgovarajuće mere kako bismo zaštitili vaše informacije. Međutim, imajte na umu da nijedna metoda prenosa putem interneta ili elektronskog skladištenja nije 100% sigurnosna.',
    },
    YOUR_RIGHTS: {
      TITLE: '6. Vaša prava',
      DESCRIPTION:
        'Imate pravo da zahtevate pristup svojim ličnim informacijama, ispravite netačne informacije ili zatražite brisanje vaših podataka.',
    },
    CONTACT_US: {
      TITLE: '7. Kontaktirajte nas',
      DESCRIPTION:
        'Ako imate bilo kakva pitanja ili zabrinutosti u vezi sa ovom politikom privatnosti, molimo vas da nas kontaktirate na sportskicentarsunrise@gmail.com.\n' +
        '\n' +
        'Za specifične zahteve ili dodatne aspekte koji se mogu primeniti na vašu situaciju, preporučujemo da se konsultujete sa pravnikom.',
    },
  },
};
