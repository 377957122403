import React, { useCallback, useEffect, useState } from 'react';
import SunriseLogo from '../../../../../customerComponents/components/SunriseLogo/SunriseLogo';
import { useStyles } from './BackOfficeHomePage.style';
import clsx from 'clsx';
import BackOfficeActiveAppointmentsTable from '../../components/BackOfficeActiveAppointmentsTable/BackOfficeActiveAppointmentsTable';
import BackOfficeUsersTable from '../../components/BackOfficeUsersTable/BackOfficeUsersTable';
import BackOfficeAllAppointmentsTable from '../../components/BackOfficeAllAppointmentsTable/BackOfficeAllAppointmentsTable';
import { useNavigate } from 'react-router';
import { isBoUserLoggedIn } from '../../../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../store/store';
import { handleNewErrorThunk } from '../../../../App/redux/errorSlice';
import { text } from '../../../../../constants/constants';
import { IStore } from '../../../../../types';
import { changePasswordBoThunk, logoutBoThunk } from '../../../BackOfficeAuth/redux/boAuthSlice';
import BookitButton from '../../../../../components/foundation/BookitButton/BookitButton';
import BookitDrawer from '../../../../../components/foundation/BookitDrawer/BookitDrawer';
import BackOfficeScheduleAppointmentsTable from '../../components/BackOfficeScheduleAppointmentsTable/BackOfficeScheduleAppointmentsTable';
import { setBoProfileThunk } from '../../redux/boProfileSlice';
import BookitAlertBox from '../../../../../components/foundation/BookitAlertBox/BookitAlertBox';

type TMenuItem = 'appointments' | 'schedule_appointments' | 'users' | 'last_14_days_active_appointments';
interface IMenuDataItem {
  title: string;
  name: TMenuItem;
}

const BackOfficeHomePage = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const isUserLoggedIn = isBoUserLoggedIn();
  const dispatch = useDispatch<AppDispatch>();
  const { loading } = useSelector((store: IStore) => store.backOfficeAuth);
  const { profile } = useSelector((store: IStore) => store.backOfficeProfile);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState<TMenuItem>('schedule_appointments');
  const [showConfirmChangePassMsg, setShowConfirmChangePassMsg] = useState(false);

  const handleLogOut = useCallback(async () => {
    try {
      await dispatch(logoutBoThunk()).unwrap();
      navigate('/login_bo');
    } catch (error) {
      dispatch(handleNewErrorThunk(error));
    }
  }, [dispatch]);

  const handleChangePass = useCallback(async () => {
    try {
      await dispatch(changePasswordBoThunk(profile.email)).unwrap();
      setShowConfirmChangePassMsg(true);
    } catch (error) {
      dispatch(handleNewErrorThunk(error));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isUserLoggedIn) {
      navigate('/login_bo');
      return;
    }
    dispatch(setBoProfileThunk());
  }, [isUserLoggedIn]);

  const menuData: IMenuDataItem[] = [
    {
      title: 'Raspored rezervacija',
      name: 'schedule_appointments',
    },
    {
      title: 'Aktivne rezervacije',
      name: 'last_14_days_active_appointments',
    },
    {
      title: 'Sve rezervacije',
      name: 'appointments',
    },
    {
      title: 'Korisnici',
      name: 'users',
    },
  ];

  const toggleDrawerOpen = () => {
    setIsDrawerOpen(!isDrawerOpen);
    setShowConfirmChangePassMsg(false);
  };

  const renderMenu = () => (
    <ul>
      {menuData.map((item) => (
        <li
          className={clsx(styles.menuText, { [styles.selectedMenuText]: selectedMenuItem === item.name })}
          onClick={() => {
            setSelectedMenuItem(item.name);
            toggleDrawerOpen();
          }}
        >
          <h3>{item.title}</h3>
        </li>
      ))}
    </ul>
  );

  const renderContent = () => {
    switch (selectedMenuItem) {
      case 'schedule_appointments':
        return <BackOfficeScheduleAppointmentsTable />;
      case 'last_14_days_active_appointments':
        return <BackOfficeActiveAppointmentsTable />;
      case 'appointments':
        return <BackOfficeAllAppointmentsTable />;
      case 'users':
        return <BackOfficeUsersTable />;
    }
  };

  const renderContentPlaceholder = () => {
    return <div className={styles.contentWrapper}>{renderContent()}</div>;
  };

  const renderMenuDrawer = () => (
    <BookitDrawer
      isOpen={isDrawerOpen}
      onIconButtonClick={() => {
        toggleDrawerOpen();
      }}
      onClose={() => setIsDrawerOpen(false)}
      childrenStyle={{ overflow: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
    >
      <div>
        <div className={styles.headerMainWrapper}>
            <SunriseLogo size={'medium'} />
          <p className={styles.titleText}>{text.BO_MENU_DRAWER.TITLE}</p>
          {/* <div className={styles.headerBottomWrapper}>
            <p className={styles.welcomeBackText}>
              Dobrodošli nazad,
              <br />
              {profile.first_name} {profile.last_name}{' '}
            </p>
          </div> */}
        </div>
        {renderMenu()}
      </div>
      <div>
        {showConfirmChangePassMsg && <BookitAlertBox type={'info'}>{text.HEADER.RESET_PASSWORD_MESSAGE}</BookitAlertBox>}
        <div className={styles.bottomButtonsWrapper}>
          <BookitButton
            variant='text'
            label={text.HEADER.LOG_OUT_BUTTON}
            onClick={handleLogOut}
            type='basic'
            color='secondary'
            loading={loading}
            style={{
              color: '#F56E0F',
              fontSize: '1rem',
              padding: 0,
              justifyContent: 'left',
              margin: '0',
              width: 'fit-content',
              textTransform: 'initial',
            }}
          />
          <BookitButton
            variant='text'
            label={text.HEADER.CHANGE_PASSWORD_BUTTON}
            onClick={handleChangePass}
            type='basic'
            color='secondary'
            loading={loading}
            style={{
              color: '#F56E0F',
              fontSize: '1rem',
              padding: 0,
              justifyContent: 'left',
              width: 'fit-content',
              textTransform: 'initial',
            }}
          />
        </div>
      </div>
    </BookitDrawer>
  );

  return (
    <div className={styles.wrapper}>
      {renderMenuDrawer()}
      {renderContentPlaceholder()}
    </div>
  );
};

export default BackOfficeHomePage;
