import theme from '../../../themes/theme';

const styles = {
  // @ts-ignore
  disabled: {
    cursor: 'not-allowed',
    '&.Mui-disabled': {
      color: 'lightgray',
      backgroundColor: theme.palette.secondary.light,
    },
  },

  textVariantButton: {
    color: theme.palette.primary.contrastText,
    textTransform: 'initial',
    padding: 0,
    // @ts-ignore
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent'
    },
  },

  containedVariantButton: {},
  outlinedVariantButton: {},
};

export default styles;
