import { makeStyles } from '@mui/styles';
import theme from '../../../themes/theme';

export const useStyles = makeStyles({
  defaultTableCell: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    padding: '0 10px 0 10px',
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '10px 0 10px 0',
    backgroundColor: theme.palette.secondary.dark,
  },

  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '30px',
    padding: '10px 0 10px 0',

    borderBottom: `1px solid ${theme.palette.secondary.light}`,

    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },

  noData: {
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.h3.fontSize,
    fontFamily: theme.typography.fontFamily,
    margin: '3rem 0 0 0',
  },
});
