import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import CountryCodesDropdownField from '../../../../components/CountryCodesDropdownField/CountryCodesDropdownField';
import BookitButton from '../../../../components/foundation/BookitButton/BookitButton';
import BookitInputField from '../../../../components/foundation/BookitInputField/BookitInputField';
import { text } from '../../../../constants/constants';
import GoogleAuth from '../../components/GoogleAuth/GoogleAuth';
import styles from './SignupPage.style';
import { useDispatch, useSelector } from 'react-redux';
import { registerThunk } from '../../redux/authSlice';
import { IProfileDTO } from '../../../../services/serviceTypes';
import { AppDispatch } from '../../../../store/store';
import SunriseLogo from '../../../../customerComponents/components/SunriseLogo/SunriseLogo';
import { useIsMobile } from '../../../../hooks/IsMobileHook';
import { handleNewErrorThunk } from '../../../App/redux/errorSlice';
import { registerSocialThunk } from '../../redux/authSocialSlice';
import { IStore } from '../../../../types';
import { Link, useLocation } from 'react-router-dom';
import theme from '../../../../themes/theme';
import BookitSnackbar from '../../../../components/foundation/BookitSnackbar/BookitSnackbar';

const SignupPage = () => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [nameValid, setNameValid] = useState(false);
  const [surnameValid, setSurnameValid] = useState(false);
  const [phoneNumberValid, setPhoneNumberValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [socialToken, setSocialToken] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const location = useLocation();
  const isMobile = useIsMobile();

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const auth = useSelector((store: IStore) => store.auth);
  const authSocial = useSelector((store: IStore) => store.authSocial);
  const { googleToken } = location.state || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setSocialToken(googleToken);
  }, [googleToken]);

  const isButtonDisabled = () => {
    if (!socialToken) {
      return !nameValid || !surnameValid || !phoneNumberValid || !emailValid || !passwordValid || !selectedCountry;
    }
    return !phoneNumberValid || !selectedCountry;
  };

  const handleEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !isButtonDisabled()) {
      handleSignupClick();
    }
  };

  const handleSignupClick = async () => {
    try {
      const phone_number = {
        country_code: selectedCountry.code,
        phone_number: `${selectedCountry.phoneCode}${phoneNumber}`,
      };
      const registerData: IProfileDTO = {
        first_name: name,
        last_name: surname,
        email: email,
        password: password,
        phone_number: phone_number,
      };

      if (socialToken) {
        await dispatch(registerSocialThunk({ token: socialToken, client: 'GOOGLE', phone_number })).unwrap();
      } else {
        await dispatch(registerThunk(registerData)).unwrap();
      }
      navigate('/reservations');
    } catch (e) {
      dispatch(handleNewErrorThunk(e));
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const renderGoogleAuthButton = () => (
    <GoogleAuth
      onSuccess={(token) => {
        setSocialToken(token);
      }}
      onError={() => setSnackbarOpen(true)}
    />
  );

  const renderDivider = () => (
    <div style={styles.dividerTextWrapper}>
      <hr style={styles.divider} />
      <span style={styles.dividerText}>{text.SIGNUP.DIVIDER_TEXT}</span>
      <hr style={styles.divider} />
    </div>
  );

  const renderNameInputFields = () => (
    <>
      <BookitInputField
        variant='outlined'
        label={text.SIGNUP.NAME}
        onChange={(e, valid) => {
          setName(e.target.value);
          setNameValid(valid);
        }}
        value={name}
        required
        fieldTheme='light'
      />
      <BookitInputField
        variant='outlined'
        label={text.SIGNUP.SURNAME}
        onChange={(e, valid) => {
          setSurname(e.target.value);
          setSurnameValid(valid);
        }}
        value={surname}
        required
        fieldTheme='light'
      />
    </>
  );

  const renderPhoneNumberFields = () => (
    <div style={styles.phoneNumberWrapper}>
      <CountryCodesDropdownField selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
      <BookitInputField
        variant='outlined'
        label={text.SIGNUP.PHONE_NUMBER}
        onChange={(e, valid) => {
          setPhoneNumber(e.target.value);
          setPhoneNumberValid(valid);
        }}
        value={phoneNumber}
        required
        fieldTheme='light'
      />
    </div>
  );

  const renderPasswordFields = () => (
    <>
      <BookitInputField
        variant='outlined'
        label={text.SIGNUP.EMAIL}
        onChange={(e, valid) => {
          setEmail(e.target.value);
          setEmailValid(valid);
        }}
        value={email}
        type='email'
        helperText={text.EMAIL_HELPER_TEXT}
        required
        fieldTheme='light'
      />
      <BookitInputField
        variant='outlined'
        label={text.SIGNUP.PASSWORD}
        onChange={(e, valid) => {
          setPassword(e.target.value);
          setPasswordValid(valid);
        }}
        value={password}
        // type='strongPassword'
        type='password'
        required
        fieldTheme='light'
      />
    </>
  );

  const renderInputFields = () => (
    <div style={styles.formWrapper}>
      {!socialToken ? renderDivider() : <p style={styles.enterPhoneNumberText}>{text.SIGNUP.ENTER_PHONE_NUMBER_TEXT}</p>}
      {!socialToken && renderNameInputFields()}
      {renderPhoneNumberFields()}
      {!socialToken && renderPasswordFields()}
    </div>
  );

  const renderLeftSide = () => (
    <div style={isMobile ? styles.mobileImageWrapper : styles.leftSideWrapper}>
      <div style={isMobile ? styles.mobileLogoWrapper : styles.logoWrapper}>
        <SunriseLogo size='medium' isLogoWhite={true} />
      </div>
      {!isMobile && (
        <div style={styles.contentWrapper}>
          <p style={styles.title}>{text.SIGNUP.SIGNUP}</p>
          <p style={styles.text}>{text.SIGNUP.WELCOME_TEXT}</p>
        </div>
      )}
    </div>
  );

  const renderRightSide = () => (
    <div style={!socialToken ? styles.rightSideWrapper : styles.rightSideWrapperEnterPhoneNumber}>
      {isMobile && !socialToken && (
        <div style={styles.mobileContentWrapper}>
          <p style={styles.titleMobile}>{text.SIGNUP.SIGNUP}</p>
          <p style={styles.text}>{text.SIGNUP.WELCOME_TEXT}</p>
        </div>
      )}
      {!socialToken && renderGoogleAuthButton()}
      <BookitSnackbar open={snackbarOpen} onClose={handleSnackbarClose} type='error' text={text.GOOGLE_AUTH_ERROR_MESSAGE} />
      {renderInputFields()}
      <div>
        {!isMobile && (
          <BookitButton
            variant='contained'
            type='basic'
            color='primary'
            label={!socialToken ? text.SIGNUP.SIGNUP : 'Potvrdi'}
            style={styles.confirmButton}
            onClick={handleSignupClick}
            disabled={isButtonDisabled()}
            loading={auth.loading.login || authSocial.loading}
          />
        )}
        {isMobile && (
          <BookitButton
            variant='contained'
            type='basic'
            color='primary'
            label={!socialToken ? text.SIGNUP.SIGNUP : 'Potvrdi'}
            style={styles.confirmButton}
            onClick={handleSignupClick}
            disabled={isButtonDisabled()}
            loading={auth.loading.login || authSocial.loading}
          />
        )}
        <p style={styles.termsAndConditionsText}>
          {text.SIGNUP.TERMS_AND_CONDITIONS_TEXT}{' '}
          <Link to='/terms-and-conditions' style={{ color: theme.palette.primary.contrastText }}>
            {text.SIGNUP.TERMS_AND_CONDITIONS_LINK}
          </Link>
        </p>
      </div>
      <div style={styles.loginButtonWrapper}>
        <span style={styles.haveAccountText}>{text.SIGNUP.HAVE_ACCOUNT}</span>
        <BookitButton
          variant='text'
          type='basic'
          label={text.SIGNUP.LOGIN_BUTTON}
          color='primary'
          onClick={() => navigate('/login')}
          style={styles.loginButton}
        />
      </div>
    </div>
  );

  return (
    <div style={isMobile ? styles.mobileWrapper : styles.wrapper} onKeyDown={handleEnterKeyDown}>
      {renderLeftSide()}
      {renderRightSide()}
    </div>
  );
};

export default SignupPage;
