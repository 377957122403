import React from 'react';
import BookitIcon from '../BookitIcon/BookitIcon';
import BookitInputField from '../BookitInputField/BookitInputField';
import { doc } from 'prettier';

export const BookitTableSearch = ({ title, value, onChangeValue, onIconPress, searchInputVisibleForKey, columnKey }) => {
  const [isInputVisible, setIsInputVisible] = React.useState(false);

  const renderInputField = () => {
    if (!isInputVisible) {
      return null;
    }

    if (columnKey !== searchInputVisibleForKey) {
      return null;
    }

    return (
      <BookitInputField
        variant={'standard'}
        label={'Pretraži'}
        size={'small'}
        focused={isInputVisible}
        onChange={(e) => {
          onChangeValue(e.target.value);
        }}
        value={value}
      />
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {title}
        <BookitIcon
          type={'search'}
          style={{ marginLeft: '10px', cursor: 'pointer', marginBottom: '0px' }}
          onClick={() => {
            onIconPress();
            setIsInputVisible((prev) => !prev);
          }}
        />
      </div>
      {renderInputField()}
    </div>
  );
};
