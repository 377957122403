import { getStyles } from '../../components/SunriseInfoPage/SunriseInfoPage.style';
import theme from '../../../themes/theme';

const parentStyles = (isMobile: boolean): Record<string, React.CSSProperties> => {
  const styles = getStyles(isMobile);

  return {
    wrapper: {
      ...styles.parentWrapper,
    },

    title: {
      ...styles.parentTitle,
    },

    content: {
      ...styles.parentContent,
    },

    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    segmentWrapper: {
      marginTop: '2rem',
    },

    itemHeader: {
      fontFamily: theme.typography.h2.fontFamily,
      fontSize: theme.typography.h2.fontSize,
      marginBottom: theme.spacing(1),
    },
    itemWrapper: {
      display: 'flex',
      flexDirection: 'row',
      width: isMobile ? '20rem' : '40rem',
      borderBottom: '1px solid gray',
      justifyContent: 'space-between',
    },
  };
};

export default parentStyles;
