import court_03 from './tenis03.JPG';
import wallet from './wallet.png';
import empty_reservations from './empty.png';

const images = {
  TENNIS_COURT_03: court_03,
  CANCEL_RESERVATION: wallet,
  NO_RESERVATIONS: empty_reservations,
};

export default images;
