import React from 'react';
import { IReservation, IStore } from '../../types';
import styles from './BlockUserDialog.style';
// import { useSelector } from 'react-redux';
import BookitDialogAdvanced from '../../components/foundation/BookitDialog/BookitDialogAdvanced';
import BookitIcon from '../../components/foundation/BookitIcon/BookitIcon';
import { text } from '../../constants/constants';

interface IProps {
  user: any;
  userFullName?: any;
  open: boolean;
  onClose: () => void;
  onConfirm: (reservation: IReservation) => void;
}

const BlockUserDialog = ({ user, userFullName, open, onClose, onConfirm }: IProps) => {
  // const { loading } = useSelector((store: IStore) => store.reservationForCancel);

  const iconType = user.status === 'BLOCKED' ? 'addUser' : 'deleteUser';
  const userName = userFullName || `${user.first_name} ${user.last_name}`;
  const title =
    user.status === 'BLOCKED' ? text.DIALOG_TEXT.BLOCK_USER_DIALOG_UNBLOCK_TITLE : text.DIALOG_TEXT.BLOCK_USER_DIALOG_BLOCK_TITLE;
  const dialogText =
    user.status === 'BLOCKED'
      ? `${text.DIALOG_TEXT.BLOCK_USER_DIALOG_UNBLOCK_TEXT} ${userName}?`
      : `${text.DIALOG_TEXT.BLOCK_USER_DIALOG_BLOCK_TEXT} ${userName}?`;

  return (
    <BookitDialogAdvanced open={open} onClose={onClose} onConfirm={onConfirm}>
      <div style={styles.container}>
        <BookitIcon type={iconType} style={styles.icon} />
        <p style={styles.title}>{title}</p>
        <p style={styles.text}>{dialogText}</p>
      </div>
    </BookitDialogAdvanced>
  );
};

export default BlockUserDialog;
