export const text = {
  MENU: 'Cenovnik',
  HOT_DRINKS: 'Topli napici',
  SOFT_DRINKS: 'Sokovi',
  WATER: 'Voda',
  BEERS: 'Pivo',
  SPIRITS: 'Žestoka pića',
  WINE: 'Vina',
};

export const hotDrinks = [
  {
    name: 'Espresso',
    price: 150,
  },
  {
    name: 'Espresso sa mlekom',
    price: 180,
  },
  {
    name: 'Kafa domaća',
    price: 150,
  },
  {
    name: 'Nes kafa',
    price: 180,
  },
  {
    name: 'Čaj',
    price: 180,
  },
  {
    name: 'Topla čokolada',
    price: 300,
  },
  {
    name: 'Frape',
    price: 300,
  },
];

export const water = [
  {
    name: 'Aqua Viva - 0,5',
    price: 150,
  },
  {
    name: 'Aqua Viva Hydroactive',
    price: 220,
  },
  {
    name: 'Aqua Viva Recharge',
    price: 220,
  },
  {
    name: 'Knjaz Miloš - kisela voda - 0,25',
    price: 200,
  },
];

export const softDrinks = [
  {
    name: 'Limunada',
    price: 250,
  },
  {
    name: 'Cedevita',
    price: 220,
  },
  {
    name: 'Coca cola - 0,33',
    price: 250,
  },
  {
    name: 'Fanta - 0,33',
    price: 250,
  },
  {
    name: 'Schweppes Bitter Lemon - 0,33',
    price: 250,
  },
  {
    name: 'Multi Sola - 0,33',
    price: 250,
  },
  {
    name: 'Gusto Jabuka - 0,2',
    price: 200,
  },
  {
    name: 'Gusto Pomorandža - 0,2',
    price: 200,
  },
  {
    name: 'Gusto Jagoda - 0,2',
    price: 200,
  },
  {
    name: 'Guarana - 0,25',
    price: 250,
  },
];

export const spirits = [
  {
    name: 'Vinjak - 0,03',
    price: 200,
  },
  {
    name: 'Pelinkovac - 0,03',
    price: 250,
  },
  {
    name: 'Domaća rakija - 0,03',
    price: 200,
  },
  {
    name: 'Ballantines - 0,03',
    price: 300,
  },
  {
    name: 'Johnnie Walker Red - 0,03',
    price: 300,
  },
  {
    name: 'Johnnie Walker Black - 0,03',
    price: 450,
  },
  {
    name: 'Jack Daniels - 0,03',
    price: 360,
  },
  {
    name: 'Chivas Regal - 0,03',
    price: 440,
  },
];

export const beers = [
  {
    name: 'Lav - 0,5',
    price: 200,
  },
  {
    name: 'Lav Twist - 0,5',
    price: 200,
  },
  {
    name: 'Zaječarsko - 0,5',
    price: 200,
  },
  {
    name: 'Jelen - 0,5',
    price: 200,
  },
  {
    name: 'Tuborg - 0,5',
    price: 250,
  },
  {
    name: 'Heineken - 0,25',
    price: 300,
  },
];

export const wines = [
  {
    name: 'Chardonnay - Belo vino',
    price: 350,
  },
  {
    name: 'Alexandria - Crno vino',
    price: 300,
  },
];
