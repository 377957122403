import { Tooltip } from '@mui/material';
import React from 'react';

interface IProps {
  title: string;
  placement: 'top' | 'bottom' | 'left' | 'right';
  children: any;
  followCursor?: boolean;
}

const BookitTooltip = ({ title, placement, children, followCursor }: IProps) => {
  return (
    <Tooltip title={title} placement={placement} followCursor={followCursor}>
      {children}
    </Tooltip>
  );
};

export default BookitTooltip;
