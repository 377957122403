import React from 'react';
import SunriseInfoPage from '../../components/SunriseInfoPage/SunriseInfoPage';
import images from '../../images/constants';
import { text } from '../../../customerComponents/constants';
import parentStyles from './SunriseTermsAndConditions.style';
import { useIsMobile } from '../../../hooks/IsMobileHook';

const SunriseTermsAndConditions = () => {
  const isMobile = useIsMobile();

  const renderContentList = (contentKey) => {
    if (!text.TERMS_AND_CONDITIONS[contentKey].DESCRIPTION_LIST) {
      return null;
    }
    return (
      <ul>
        {text.TERMS_AND_CONDITIONS[contentKey].DESCRIPTION_LIST.map((listItem) => (
          <li key={listItem}>{listItem}</li>
        ))}
      </ul>
    );
  };

  const renderContent = () => {
    return Object.keys(text.TERMS_AND_CONDITIONS).map((key, index) => {
      return (
        <div style={parentStyles(isMobile).termWrapper}>
          <p style={parentStyles(isMobile).titleText}>{text.TERMS_AND_CONDITIONS[key].TITLE}</p>
          <p style={parentStyles(isMobile).descriptionText}>{text.TERMS_AND_CONDITIONS[key].DESCRIPTION}</p>
          {renderContentList(key)}
          {text.TERMS_AND_CONDITIONS[key].DESCRIPTION_2 && (
            <p style={parentStyles(isMobile).descriptionText}>{text.TERMS_AND_CONDITIONS[key].DESCRIPTION_2}</p>
          )}
        </div>
      );
    });
  };

  return (
    <SunriseInfoPage
      topImageSource={images.KIDS_CELEBRATION}
      leftImage1={images.TEAM_04}
      leftImage2={images.SCHOOL3}
      leftImage3={images.FOOTBALL_FIELD_02}
      rightImage={images.FOOTBALL_GAME_01}
    >
      <div style={parentStyles(isMobile).wrapper}>
        <h1 style={parentStyles(isMobile).title}>{text.TERMS_AND_CONDITIONS.TITLE}</h1>
        <div style={parentStyles(isMobile).content}>{renderContent()}</div>
      </div>
    </SunriseInfoPage>
  );
};

export default SunriseTermsAndConditions;
