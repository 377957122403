import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../store/store';
import { IStore } from '../../../../../types';
import { getBoUsersThunk } from '../../redux/boUsersSlice';
import BookitTable from '../../../../../components/foundation/BookitTable/BookitTable';
import { formatDateToYearMonthDay } from '../../../../../utils/utils';
import { useStyles } from './BackOfficeUsersTable.style';
import { BackOfficeBlockUserAction } from '../BackOfficeBlockUserAction/BackOfficeBlockUserAction';
import clsx from 'clsx';
import BookitButton from '../../../../../components/foundation/BookitButton/BookitButton';
import { text } from '../../../../../constants/constants';

type TFilterStatus = 'ALL' | 'ACCOUNT_NOT_VERIFIED' | 'BLOCKED' | 'ACTIVE' | null;
interface IButtonDataItem {
  label: string;
  filter: TFilterStatus;
  handleClick: () => void;
}

const BackOfficeUsersTable = () => {
  const styles = useStyles();

  const { allUsers } = useSelector((state: IStore) => state.backOfficeUsers);
  const [users, setUsers] = useState([]);
  const [activeFilter, setActiveFilter] = useState<TFilterStatus>('ALL');

  const dispatch = useDispatch<AppDispatch>();

  const usersPreparation = (filterByStatus: TFilterStatus = null) => {
    if (allUsers && allUsers.length > 0) {
      const sortedUsers = [...allUsers].sort((a, b) => {
        const aDate = new Date(a.created_at).getTime();
        const bDate = new Date(b.created_at).getTime();
        return bDate - aDate;
      });

      const preparedUsers = prepareTableData(sortedUsers);
      switch (filterByStatus) {
        case 'ACTIVE':
        case 'ACCOUNT_NOT_VERIFIED':
        case 'BLOCKED':
          const filteredPreparedUsers = preparedUsers.filter((preparedUser) => preparedUser.status.content === filterByStatus);
          setUsers(filteredPreparedUsers);
          break;
        case 'ALL':
        default:
          setUsers(preparedUsers);
      }
    }
  };

  useEffect(() => {
    dispatch(getBoUsersThunk());
  }, []);

  useEffect(() => {
    usersPreparation(activeFilter);
  }, [allUsers, activeFilter]);

  const buttonsData: IButtonDataItem[] = [
    {
      label: text.BO_USERS.ALL_USERS,
      filter: 'ALL',
      handleClick: () => setActiveFilter('ALL'),
    },
    {
      label: text.BO_USERS.ACTIVE_USERS,
      filter: 'ACTIVE',
      handleClick: () => setActiveFilter('ACTIVE'),
    },
    {
      label: text.BO_USERS.BLOCKED_USERS,
      filter: 'BLOCKED',
      handleClick: () => setActiveFilter('BLOCKED'),
    },
    {
      label: text.BO_USERS.AWAITING_VERIFICATION_USERS,
      filter: 'ACCOUNT_NOT_VERIFIED',
      handleClick: () => setActiveFilter('ACCOUNT_NOT_VERIFIED'),
    },
  ];

  const prepareTableData = (initialData) => {
    return initialData.map((user, index) => ({
      id: { columnName: user?.id, content: user?.id, hidden: true },
      userNo: { columnName: '#', content: index + 1, style: styles.tableExtraSmallCell },
      creationDate: {
        columnName: text.BO_USERS.REGISTRATION_DATE,
        content: formatDateToYearMonthDay(user?.created_at),
        style: styles.tableMediumCell,
      },
      name: { columnName: text.BO_USERS.NAME, content: `${user?.first_name} ${user?.last_name}` },
      phoneNumber: {
        columnName: text.BO_USERS.PHONE,
        content: `${user?.phone_number.international_format}`,
        style: styles.tableMediumCell,
      },
      email: { columnName: text.BO_USERS.EMAIL, content: `${user?.email}` },
      status: { columnName: text.BO_USERS.STATUS, content: `${user?.status}` },
      action: {
        columnName: '',
        content: user?.status && <BackOfficeBlockUserAction user={user} />,
        style: clsx(styles.tableActionCell),
      },
    }));
  };

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.buttonsWrapper}>
        {buttonsData.map(({ label, filter, handleClick }, index) => (
          <BookitButton
            key={index}
            style={{ marginRight: '0.5rem' }}
            variant={filter === activeFilter ? 'contained' : 'outlined'}
            label={label}
            type='basic'
            color='primary'
            onClick={handleClick}
          />
        ))}
      </div>
      <div className={styles.tableWrapper}>
        {users.length > 0 ? <BookitTable tableData={users} /> : <p className={styles.noData}>{text.BO_NO_DATA}</p>}
      </div>
    </div>
  );
};

export default BackOfficeUsersTable;
