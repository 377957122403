import theme from '../../themes/theme';

const styles: Record<string, React.CSSProperties> = {
  mainContainer: {
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    padding: '1rem 0 0 0',
  },
  reservationDate: {
    textAlign: 'left',
    margin: '0 0 0 1rem',
    fontWeight: theme.typography.h5.fontWeight,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  data: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 1rem 0.5rem 1rem',
  },
  dataLabel: {
    color: theme.palette.secondary.light,
  },
  deleteButton: {
    border: '1px solid rgba(128, 128, 128, 0.2)',
    borderRadius: '0.1rem',
    padding: '0.15rem',
    color: 'gray',
    margin: '0 1rem 0 0',
    cursor: 'pointer',
    // @ts-ignore
    '&:hover': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },

  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    aspectRatio: '3 / 1',
  },

  divider: {
    color: theme.palette.secondary.light,
    border: '1px solid',
  },

  dialogText: {
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.body1.fontFamily,
  },

  noteWrapper: {
    margin: '0 0 1.5rem 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    color: theme.palette.primary.contrastText,
  },

  noteTitle: {
    padding: '0 0 0.5rem 1rem',
    margin: 0,
    color: theme.palette.secondary.light,
  },

  note: {
    width: '100%',
    // @ts-ignore
    '& .MuiOutlinedInput-root': {
      color: theme.palette.primary.contrastText,
      '& fieldset': {
        borderColor: theme.palette.secondary.light,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.secondary.light,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary.light,
        borderWidth: '1px',
      },
    },
    '& .MuiInputBase-input': {
      color: theme.palette.primary.contrastText,
    // textAlign: 'center',
    padding: '0 0 0 0.2rem'

    },
  },
};

export default styles;
