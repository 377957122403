import { makeStyles } from '@mui/styles';
import theme from '../../../../../themes/theme';

export const useStyles = makeStyles({
  mainWrapper: {
    width: '90%',
    paddingTop: '3rem',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },

  buttonsWrapper: {
    marginBottom: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
  },

  tableWrapper: {
    overflowY: 'auto',
    maxHeight: '90%',
  },

  noData: {
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.h2.fontSize,
    fontFamily: theme.typography.h2.fontFamily,
    margin: '3rem 0 0 0',
  },

  tableExtraSmallCell: {
    flex: '0.1 !important',
  },
  tableSmallCell: {
    flex: '0.3 !important',
  },
  tableMediumCell: {
    flex: '0.5 !important',
  },
  tableLargeCell: {
    flex: '0.7 !important',
  },

  tableActionCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    flex: 0.2,
    '&:hover': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
});
