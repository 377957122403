import React from 'react';
import BookitButton from '../../../components/foundation/BookitButton/BookitButton';
import { text } from '../../../constants/constants';
import { useNavigate, useLocation } from 'react-router';
import { getStyles } from './SunriseNavigationButtons.style';
import { useIsMobile } from '../../../hooks/IsMobileHook';

interface IProps {
  isRowLayout?: boolean;
  style?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  handleCloseDrawer?: () => void;
}

const SunriseNavigationButtons = ({ isRowLayout = false, style, buttonStyle, handleCloseDrawer }: IProps) => {
  const isMobile = useIsMobile();
  const location = useLocation();
  const styles = getStyles(isMobile, isRowLayout, location.pathname);
  const navigate = useNavigate();

  const infoButtonsConfig: Array<{
    variant: 'text' | 'contained' | 'outlined';
    label: string;
    type: 'basic' | 'routerLink' | 'timePicker' | 'dayPicker';
    routerPath?: string;
    onClick?: () => void;
  }> = [
    { variant: 'text', label: text.FOOTER.ABOUT_US, type: 'routerLink', routerPath: '/about-us' },
    { variant: 'text', label: text.FOOTER.LEAGUES, type: 'routerLink', routerPath: '/leagues' },
    { variant: 'text', label: text.FOOTER.FOOTBALL_SCHOOL, type: 'routerLink', routerPath: '/football' },
    { variant: 'text', label: text.FOOTER.TENNIS_SCHOOL, type: 'routerLink', routerPath: '/tennis' },
    { variant: 'text', label: text.FOOTER.RECREATIONAL_SPORTS, type: 'routerLink', routerPath: '/recreational-sport' },
    {
      variant: 'text',
      label: text.FOOTER.CONTACT,
      type: 'basic',
      onClick: () => {
        navigate('/', { state: { scrollTo: 'contact' } });
        if (handleCloseDrawer) handleCloseDrawer();
      },
    },
  ];

  return (
    <div style={{ ...styles.infoButtonsWrapper, ...style }}>
      {infoButtonsConfig.map((button, index) => (
        <BookitButton
          key={index}
          variant={button.variant}
          label={button.label}
          type={button.type}
          routerPath={button.routerPath}
          style={{ ...styles.infoButtons(button.routerPath), ...buttonStyle }}
          disableRipple
          onClick={button.onClick}
        />
      ))}
    </div>
  );
};

export default SunriseNavigationButtons;
