import { makeStyles } from '@mui/styles';
import theme from '../../../../../themes/theme';

export const useStyles = makeStyles({
  iconWrapper: {
    cursor: 'pointer',
    '&:hover': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
});
