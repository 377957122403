import { text } from '../../../../../constants/constants';
import { Tooltip } from '@mui/material';
import BookitIcon from '../../../../../components/foundation/BookitIcon/BookitIcon';
import React, { useState } from 'react';
import { blockBoUserThunk, unblockBoUserThunk } from '../../redux/boUsersSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../store/store';
import { handleNewErrorThunk } from '../../../../App/redux/errorSlice';
import BlockUserDialog from '../../../../../dialogs/BlockUserDialog/BlockUserDialog';
import { getBoAppointmentsThunk } from '../../redux/boAppointmentsSlice';
import { useStyles } from './BackOfficeBlockUserAction.style';

interface IProps {
  user: any;
  blockUserCallback?: () => void;
  unblockUserCallback?: () => void;
  onClose?: () => void;
}

export const BackOfficeBlockUserAction = ({ user, onClose, blockUserCallback }: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const styles = useStyles();
  const [isBlockUserDialogOpen, setIsBlockUserDialogOpen] = useState(false);
  if (!user) {
    return null;
  }

  const handleBlockUser = async (userId: string) => {
    try {
      await dispatch(blockBoUserThunk({ userId })).unwrap();
      dispatch(getBoAppointmentsThunk());
      if (blockUserCallback) {
        blockUserCallback();
      }
    } catch (e) {
      dispatch(handleNewErrorThunk(e));
    } finally {
      setIsBlockUserDialogOpen(false);
      if (onClose) onClose();
    }
  };

  const handleUnblockUser = async (userId: string) => {
    try {
      await dispatch(unblockBoUserThunk({ userId })).unwrap();
      dispatch(getBoAppointmentsThunk());
    } catch (e) {
      dispatch(handleNewErrorThunk(e));
    } finally {
      setIsBlockUserDialogOpen(false);
    }
  };

  const title = user.status === 'BLOCKED' ? text.BO_HOME.UNBLOCK_USER_TOOLTIP : text.BO_HOME.BLOCK_USER_TOOLTIP;
  const handleClick = user.status === 'BLOCKED' ? () => handleUnblockUser(user.id) : () => handleBlockUser(user.id);
  const iconType = user.status === 'BLOCKED' ? 'addUser' : 'deleteUser';

  return (
    <>
      <Tooltip placement={'top'} title={title}>
        <div className={styles.iconWrapper} onClick={() => setIsBlockUserDialogOpen(true)}>
          <BookitIcon type={iconType} />
        </div>
      </Tooltip>
      <BlockUserDialog
        user={user}
        userFullName={user.userFullName}
        open={isBlockUserDialogOpen}
        onClose={() => {
          setIsBlockUserDialogOpen(false);
        }}
        onConfirm={handleClick}
      />
    </>
  );
};
