import React from 'react';

interface IProps {
  style: React.CSSProperties;
  children: any;
}

const BookitHeader = ({ style, children }: IProps) => {
  return (
    <>
      <div style={style}>
        {children}
      </div>
    </>
  );
};

export default BookitHeader;
