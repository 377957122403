import { makeStyles } from '@mui/styles';
import theme from '../../../../../themes/theme';

export const useStyles = makeStyles({
  mainWrapper: {
    width: '90%',
    paddingTop: '3rem',
    height: '100%',
  },

  calendarWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    marginTop: '1rem',
    marginBottom: '1rem',
  },

  tableWrapper: {
    overflowY: 'auto',
    height: '95%',
  },

  noData: {
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.h2.fontSize,
    fontFamily: theme.typography.h2.fontFamily,
    margin: '3rem 0 0 0',
  },

  tableExtraSmallCell: {
    flex: '0.1 !important',
  },
  tableSmallCell: {
    flex: '0.3 !important',
  },
  tableMediumCell: {
    flex: '0.5 !important',
  },
  tableLargeCell: {
    flex: '0.7 !important',
  },
  tableTruncatedCell: {
    display: 'table-cell',
    textOverflow: 'ellipsis',
    textAlign: 'left',
  },
});
