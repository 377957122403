import theme from '../../themes/theme';

const styles: Record<string, React.CSSProperties> = {
  mainContainer: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.dark,
    fontFamily: theme.typography.fontFamily,
    padding: '10px 15px 10px 15px',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    boxShadow: `
    rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
    rgba(0, 0, 0, 0.14) 0px 4px 5px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 10px 0px
  `,
    overflow: 'hidden',
  },

  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  divider: {
    height: '3rem',
    borderRight: `1px solid ${theme.palette.primary.main}`,
    margin: '0 0.8rem 0 0.8rem',
  },
};

export default styles;
