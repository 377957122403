export const text = {
  PAGE_TITLE: 'Cenovnik',
  CARD_HEADER_TITLE: 'Naziv terena',
  CARD_HEADER_PRICE_TITLE: 'Cena termina po satu',
  FOOTBALL_CARD_TITLE: 'Fudbal',
  TENNIS_CARD_TITLE: 'Tenis',
  BASKETBALL_CARD_TITLE: 'Košarka',
  VOLLEYBALL_CARD_TITLE: 'Odbojka',
};

export const fieldName = {
  FOOTBALL: {
    FIELD_1: 'Hala 1',
    FIELD_2: 'Hala 2',
    FIELD_3: 'Hala 3',
    FIELD_4: 'Tereni 4 i 5 napolju. U zimskim uslovima u balon hali', // change name
    FIELD_5: 'Srednji teren 50x30m',
    FIELD_6: 'Novi veliki teren 101x60m',
  },
  TENNIS: {
    FIELD_1: 'Teren 1',
    FIELD_2: 'Teren 2',
    FIELD_3: 'Teren 3',
    FIELD_4: 'Teren 4',
    MULTISPORT: 'Multisport teren hala',
  },
  BASKETBALL: {
    MULTISPORT: 'Multisport teren hala',
    HALF_COURT: 'Basket (1 koš)',
  },
  VOLLEYBALL: {
    MULTISPORT: 'Multisport teren hala',
  },
};

export const fieldTime = {
  FULL_DAY: 'Termini 08:00 - 23:00h',
  TENNIS_DAY: 'Dnevni termini 08:00 - 20:00h',
  TENNIS_NIGHT: 'Noćni termini 20:00 - 23:00h',
};

export const price = {
  FOOTBALL: {
    FIELD_1: 4500,
    FIELDS_2_3: 4000,
    FIELDS_4_5: 3500, // change name
    FIELD_6: 4000,
  },
  TENNIS: {
    FIELDS_1_2_3_4: 600,
    FIELDS_1_2_3_4_NIGHT: 800,
    MULTISPORT: 1800,
  },
  BASKETBALL: {
    MULTISPORT: 3500,
    HALF_COURT: 2000,
  },
  VOLLEYBALL: {
    MULTISPORT: 3500,
  },
};
