import React from 'react';
import SunriseInfoPage from '../../components/SunriseInfoPage/SunriseInfoPage';
import images from '../../images/constants';
import { text, beers, hotDrinks, softDrinks, water, wines, spirits } from '../../menuContants';
import parentStyles from './SunriseMenuPricingPage.style';
import { useIsMobile } from '../../../hooks/IsMobileHook';

const SunriseMenuPricingPage = () => {
  const isMobile = useIsMobile();

  const renderSegment = (title, drinks) => (
    <div style={parentStyles(isMobile).contentWrapper}>
      <h2 style={parentStyles(isMobile).itemHeader}>{title}</h2>
      {drinks.map((item, i) => (
        <div
          key={i}
          style={{ ...parentStyles(isMobile).itemWrapper, borderBottom: drinks.length - 1 === i ? 'none' : '1px solid gray' }}
        >
          <p>{item.name}</p>
          <p>{item.price}</p>
        </div>
      ))}
    </div>
  );

  return (
    <SunriseInfoPage
      topImageSource={images.LEAGUES2}
      leftImage1={images.ORANGE_01}
      leftImage2={images.ORANGE_02}
      leftImage3={images.FOOTBALL_FIELD_04}
      rightImage={images.TABLES}
    >
      <div style={parentStyles(isMobile).wrapper}>
        <h1 style={parentStyles(isMobile).title}>{text.MENU}</h1>
        <div style={parentStyles(isMobile).content}>
          {renderSegment(text.HOT_DRINKS, hotDrinks)}
          {renderSegment(text.WATER, water)}
          {renderSegment(text.SOFT_DRINKS, softDrinks)}
          {renderSegment(text.BEERS, beers)}
          {renderSegment(text.WINE, wines)}
          {renderSegment(text.SPIRITS, spirits)}
        </div>
      </div>
    </SunriseInfoPage>
  );
};

export default SunriseMenuPricingPage;
