import images from '../../../../images/constants';
import theme from '../../../../themes/theme';

// @ts-ignore
const styles: Record<string, React.CSSProperties> = {
  wrapper: {
    display: 'flex',
    height: '100vh',
    userSelect: 'none',
  },

  mobileWrapper: {
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
  },

  leftSideWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundImage: `url(${images.TENNIS_COURT_03})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    textShadow: '2px 2px 6px #000000',
  },

  mobileImageWrapper: {
    backgroundImage: `url(${images.TENNIS_COURT_03})`,
    backgroundSize: 'cover',
    height: '18rem',
  },

  logoWrapper: {
    position: 'absolute',
    top: '1.5rem',
    left: '2rem',
  },

  mobileLogoWrapper: {
    position: 'absolute',
    top: '13rem',
    left: '1rem',
  },

  contentWrapper: {
    padding: '2rem',
  },

  mobileContentWrapper: {
    padding: '0 0 2rem 1rem',
  },

  title: {
    fontSize: '2.5rem',
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.primary.contrastText,
  },

  titleMobile: {
    fontSize: theme.typography.h2.fontSize,
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.primary.contrastText,
  },

  text: {
    fontSize: theme.typography.body1.fontSize,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.contrastText,
  },

  rightSideWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: theme.palette.secondary.main,
  },

  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },

  loginButtonWrapper: {
    marginTop: '1rem',
    width: '23rem',
    height: '2.75rem',
    marginBottom: '1rem',
  },

  mobileLoginButtonWrapper: {
    marginTop: '1rem',
    width: '23rem',
    height: '2.75rem',
    marginBottom: '3rem',
  },

  loginButton: {
    width: '100%',
    height: '100%',
    borderRadius: '1px',
    margin: 0,
    // @ts-ignore
    '&.Mui-disabled': {
      color: 'lightgray',
      backgroundColor: theme.palette.secondary.light,
    },
  },

  forgotPasswordWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '1rem',
  },

  forgotPasswordButton: {
    textTransform: 'initial',
    fontSize: theme.typography.body1.fontSize,
    textDecoration: 'underline',
    color: theme.palette.primary.contrastText,
    margin: '0.5rem 0.5rem 0.5rem 0',
    // @ts-ignore
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.light,
    },
  },

  dividerTextWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    margin: '1rem 0 2rem 0',
  },

  divider: {
    width: '100%',
    margin: '0',
    borderColor: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.light,
  },

  dividerText: {
    padding: '0 2rem',
    whiteSpace: 'nowrap',
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
  },

  signupButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '1.5rem 0 1.5rem 0',
  },

  signupButton: {
    textTransform: 'initial',
    fontSize: theme.typography.body1.fontSize,
    textDecoration: 'underline',
    marginTop: '1px',
    color: theme.palette.primary.main,
    padding: '0 0 0 0.5rem',
    // @ts-ignore
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.light,
    },
  },
  noAccountText: {
    color: 'white',
    fontFamily: theme.typography.fontFamily,
  },
};

export default styles;
