import React from 'react';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton } from '@mui/material';
import { TIcon } from '../../../types';
import HideImageOutlinedIcon from '@mui/icons-material/HideImageOutlined';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import EastIcon from '@mui/icons-material/East';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CheckIcon from '@mui/icons-material/Check';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SearchIcon from '@mui/icons-material/Search';

interface IProps {
  type: TIcon;
  onClick?: () => void;
  style?: React.CSSProperties;
  color?: any;
}

const BookitIcon = ({ type, onClick, style, color }: IProps) => {
  let icon;

  switch (type) {
    case 'delete':
      icon = <DeleteForeverOutlinedIcon onClick={onClick} color={color} sx={style} />;
      break;
    case 'facebook':
      icon = <FacebookIcon onClick={onClick} sx={style} />;
      break;
    case 'instagram':
      icon = <InstagramIcon onClick={onClick} sx={style} />;
      break;
    case 'menu':
      icon = (
        <IconButton onClick={onClick} color={color} sx={style}>
          <MenuIcon />
        </IconButton>
      );
      break;
    case 'close':
      icon = <CloseIcon onClick={onClick} sx={style} />;
      break;
    case 'leftArrow':
      icon = <ArrowLeftIcon onClick={onClick} sx={style} />;
      break;
    case 'rightArrow':
      icon = <ArrowRightIcon onClick={onClick} sx={style} />;
      break;

    case 'leftCircleArrow':
      icon = <ArrowCircleLeftIcon onClick={onClick} sx={style} />;
      break;

    case 'rightCircleArrow':
      icon = <ArrowCircleRightIcon onClick={onClick} sx={style} />;
      break;

    case 'account':
      icon = <AccountCircleIcon onClick={onClick} sx={style} />;
      break;
    case 'calendar':
      icon = <CalendarMonthIcon sx={style} />;
      break;
    case 'edit':
      icon = <EditIcon onClick={onClick} sx={style} />;
      break;
    case 'imagePlaceholder':
      icon = <HideImageOutlinedIcon sx={style} />;
      break;
    case 'timeslotsPlaceholder':
      icon = <EventBusyIcon sx={style} />;
      break;
    case 'refresh':
      icon = <RefreshIcon onClick={onClick} sx={style} />;
      break;
    case 'addUser':
      icon = <PersonAddAlt1Icon onClick={onClick} color={color} sx={style} />;
      break;
    case 'deleteUser':
      icon = <PersonOffIcon onClick={onClick} color={color} sx={style} />;
      break;
    case 'mapPin':
      icon = <FmdGoodOutlinedIcon onClick={onClick} sx={style} />;
      break;
    case 'phone':
      icon = <LocalPhoneOutlinedIcon onClick={onClick} sx={style} />;
      break;
    case 'email':
      icon = <EmailOutlinedIcon onClick={onClick} sx={style} />;
      break;
    case 'longRightArrow':
      icon = <EastIcon onClick={onClick} sx={style} />;
      break;
    case 'passwordVisibilityOn':
      icon = <VisibilityOutlinedIcon onClick={onClick} sx={style} />;
      break;
    case 'passwordVisibilityOff':
      icon = <VisibilityOffOutlinedIcon onClick={onClick} sx={style} />;
      break;
    case 'checkmark':
      icon = <CheckIcon onClick={onClick} sx={style} />;
      break;
    case 'heart':
      icon = <FavoriteBorderIcon onClick={onClick} sx={style} />;
      break;
    case 'search':
      icon = <SearchIcon onClick={onClick} sx={style} />;
      break;
    default:
      icon = null;
  }

  return <span>{icon}</span>;
};

export default BookitIcon;
