import React, { useEffect, useState } from 'react';
import { cancelBoAppointmentThunk, getBoAppointmentsThunk } from '../../redux/boAppointmentsSlice';
import { formatDateToHoursString, formatDateToRegular, formatDateToYearMonthDay } from '../../../../../utils/utils';
import { Tooltip } from '@mui/material';
import { text } from '../../../../../constants/constants';
import BookitIcon from '../../../../../components/foundation/BookitIcon/BookitIcon';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../../types';
import { AppDispatch } from '../../../../../store/store';
import { useStyles } from './BackOfficeActiveAppointmentsTable.style';
import BookitTable from '../../../../../components/foundation/BookitTable/BookitTable';
import { BackOfficeBlockUserAction } from '../BackOfficeBlockUserAction/BackOfficeBlockUserAction';
import CancelReservationDialog from '../../../../../dialogs/CancelReservationDialog/CancelReservationDialog';
import theme from '../../../../../themes/theme';
import { handleNewErrorThunk } from '../../../../App/redux/errorSlice';
import BackOfficeRefresh from '../BackOfficeRefresh/BackOfficeRefresh';

interface ITableDataItem {
  columnName: string;
  value: string | JSX.Element;
  hidden?: boolean;
  style?: React.CSSProperties;
}

const BackOfficeActiveAppointmentsTable = () => {
  const styles = useStyles();

  const [tableData, setTableData] = useState<ITableDataItem[]>([]);
  const [appointmentForCancel, setAppointmentForCancel] = useState(null);
  const [isCancelReservationDialogOpen, setIsCancelReservationDialogOpen] = useState(false);

  const appointments = useSelector((state: IStore) => state.backOfficeAppointments);
  const dispatch = useDispatch<AppDispatch>();

  const searchAvailable = ['userFullName', 'note', 'serviceName'];

  const getBoAppointments = async () => {
    try {
      await dispatch(getBoAppointmentsThunk()).unwrap();
    } catch (e) {
      dispatch(handleNewErrorThunk(e));
    }
  };

  useEffect(() => {
    getBoAppointments();
  }, []);

  useEffect(() => {
    if (appointments?.activeAppointments) {
      const mappedTableData = tableDataMap(appointments.activeAppointments);
      setTableData(mappedTableData);
    }
  }, [appointments]);

  const handleCancelAppointment = async (appointmentId) => {
    try {
      await dispatch(cancelBoAppointmentThunk(appointmentId)).unwrap();
      setIsCancelReservationDialogOpen(false);
      setAppointmentForCancel(null);
    } catch (e) {
      dispatch(handleNewErrorThunk(e));
    }
  };

  const handleRefresh = async () => {
    await getBoAppointments();
  };

  const tableDataMap = (initialData) => {
    return initialData.map((item, index) => {
      return {
        id: { columnName: item.id, content: item.id, hidden: true },
        no: { columnName: '#', content: index + 1, style: styles.tableExtraSmallCell },
        date: { columnName: 'Datum', content: formatDateToYearMonthDay(item.start_date), style: styles.tableMediumCell },
        time: { columnName: 'Vreme', content: formatDateToHoursString(item.start_date), style: styles.tableSmallCell },
        serviceName: { columnName: 'Sport', content: item.service.name, style: styles.tableMediumCell },
        resourceName: { columnName: 'Teren', content: item.resource.name },
        userFullName: {
          columnName: 'Ime i prezime',
          content: item.retail_user?.first_name ? `${item.retail_user?.first_name} ${item.retail_user?.last_name}` : '-',
          style: styles.tableLargeCell,
        },
        userPhoneNumber: {
          columnName: 'Broj telefona',
          content: item.retail_user?.phone_number.international_format
            ? `${item.retail_user?.phone_number.international_format}`
            : '-',
          style: styles.tableLargeCell,
        },
        userEmail: { columnName: 'Email', content: item.retail_user?.email ? `${item.retail_user?.email}` : '-' },
        note: {
          columnName: 'Beleška',
          content: `${item.note}`,
          style: styles.tableNoteCell,
        },
        deleteAction: {
          columnName: '',
          content: renderDeleteAction(item),
          style: styles.tableActionCell,
        },
        blockAction: {
          columnName: '',
          content: <BackOfficeBlockUserAction user={item.retail_user} />,
          style: styles.tableActionCell,
        },
      };
    });
  };

  const renderDeleteAction = (appointment) => {
    return (
      <Tooltip placement={'top'} title={text.BO_HOME.TOOLTIP}>
        <div
          onClick={() => {
            setIsCancelReservationDialogOpen(true);
            setAppointmentForCancel(appointment);
          }}
        >
          <BookitIcon type={'delete'} />
        </div>
      </Tooltip>
    );
  };

  const renderDialogs = () => (
    <>
      <CancelReservationDialog
        open={isCancelReservationDialogOpen}
        onClose={() => {
          setIsCancelReservationDialogOpen(false);
        }}
        onConfirm={() => {
          handleCancelAppointment(appointmentForCancel?.id);
        }}
      >
        <p
          style={{
            textAlign: 'center',
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.body1.fontSize,
            color: theme.palette.primary.contrastText,
            margin: '1rem 0 0 0',
          }}
        >
          {appointmentForCancel &&
            `Termin ${formatDateToRegular(appointmentForCancel?.start_date)} u ${formatDateToHoursString(
              appointmentForCancel?.start_date,
            )} na terenu ${appointmentForCancel?.resource.name} biće otkazan.`}
        </p>
      </CancelReservationDialog>
    </>
  );

  return (
    <div className={styles.mainWrapper}>
      <BackOfficeRefresh onRefresh={handleRefresh} />
      <div className={styles.tableWrapper}>
        {tableData.length > 0 ? (
          <BookitTable tableData={tableData} searchAvailable={searchAvailable} />
        ) : (
          <p className={styles.noData}>{text.BO_NO_DATA}</p>
        )}
      </div>
      {renderDialogs()}
    </div>
  );
};

export default BackOfficeActiveAppointmentsTable;
